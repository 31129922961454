import { useState } from "react";
import { connect } from "react-redux";
import { setLanguages, setScheme, setUser } from '../../redux/actions'
import LayoutSectionComponentHeader from "./landing/header";
import LayoutSectionComponentSplash from "./landing/splash";
import LayoutSectionComponentArticle from "./landing/article";
import LayoutSectionComponentPlans from "./landing/plans";
import LayoutSectionComponentReviews from "./landing/reviews";
import LayoutSectionComponentQualities from "./landing/qualities";
import LayoutSectionComponentContact from "./landing/contact";
import LayoutSectionComponentSpecifications from "./landing/specifications";
import LayoutSectionComponentDownloads from "./landing/downloads";
import LayoutSectionComponentDefinitions from "./landing/definitions";
import LayoutSectionComponentGlossary from "./landing/glossary";
import LayoutSectionComponentImages from "./landing/images";

import './LayoutSectionComponent.scss'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser, setScheme
}

interface DispatchProps {
    componentIndex: number,
    sectionIndex: number,
    scheme: any,
    onDeselectComponent: () => void
}
type Props = DispatchProps

function LayoutSectionComponent(props: Props) {
    const [isOpen, setIsOpen] = useState(false)
    const type = props.scheme[props.sectionIndex].components[props.componentIndex].type

    const renderComponentByType = () => {
        console.log('Component:', props.scheme[props.sectionIndex].components[props.componentIndex])
        switch (type) {
            case 'Header':
                return <LayoutSectionComponentHeader sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Splash':
                return <LayoutSectionComponentSplash sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Article':
                return <LayoutSectionComponentArticle sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Plans':
                return <LayoutSectionComponentPlans sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Reviews':
                return <LayoutSectionComponentReviews sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Qualities':
                return <LayoutSectionComponentQualities sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Contact':
                return <LayoutSectionComponentContact sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Specifications':
                return <LayoutSectionComponentSpecifications sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Downloads':
                return <LayoutSectionComponentDownloads sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Definitions':
                return <LayoutSectionComponentDefinitions sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Glossary':
                return <LayoutSectionComponentGlossary sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            case 'Images':
                return <LayoutSectionComponentImages sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />

            default:
                return 'foo';
        }
    }
    return (
        <>
            <div className="Layout-section-components-component Layout-slide-left" >
                <div className="Layout-back" onClick={props.onDeselectComponent}>
                    <i className="la la-arrow-left" />
                    Volver a todos los componentes
                </div>
                {
                    <div className="Layout-section-components-component-configuration">

                        {
                            renderComponentByType()
                        }
                    </div>
                }
            </div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSectionComponent)
