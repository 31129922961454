import { useState } from "react";
import { connect } from "react-redux";
import { setLanguages, setUser } from '../../../../redux/actions'
import LayoutInputToggle from "../toggle/LayoutInputToggle";
import './index.scss'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser
}

interface DispatchProps {
    title: string,
    icon: string,
    onSave: (data: any) => void
    defaultValue: any,
    options: {
        value: string,
        label: string
    }[]
}
type Props = DispatchProps

function LayoutInputSelect(props: Props) {

    const [value, setValue] = useState(props.defaultValue)

    return (
        <div className="Layout-text" >
            <LayoutInputToggle title={props.title} icon={props.icon}>
                <div className="Layout-text-input">
                    <input type={'text'} value={value} onChange={(event) => {
                        setValue(event.target.value)
                        props.onSave(event.target.value)
                    }} />
                </div>
            </LayoutInputToggle>

        </div>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutInputSelect)

