import {
  CLEAR,
  CLEAR_PROJECT,
  SET_APPLICATION,
  SET_BACKEND,
  SET_BACKEND_GITHUB,
  SET_DATABASE,
  SET_DATABASE_GITHUB,
  SET_FRONTEND,
  SET_FRONTEND_GITHUB,
  SET_LANGUAGE,
  SET_LANGUAGES,
  SET_LANGUAGES_GITHUB,
  SET_MODE,
  SET_ORGANIZATION,
  SET_PROFILE,
  SET_PROFILE_GITHUB,
  SET_REPOSITORY,
  SET_SCENE,
  SET_SCHEME,
  SET_SCHEME_GITHUB,
  SET_THEME,
  SET_USER
} from '../constants/actions';

const initialState = {
  user: false,
  scheme: null
};

function rootReducer(state = initialState, action) {
  if (action.type === SET_ORGANIZATION) {
    return Object.assign({}, state, {
      organization: action.payload,
    });
  }
  if (action.type === SET_SCHEME) {
    return Object.assign({}, state, {
      scheme: action.payload,
    });
  }
  if (action.type === SET_REPOSITORY) {
    return Object.assign({}, state, {
      repository: action.payload,
    });
  }
  if (action.type === SET_USER) {
    return Object.assign({}, state, {
      user: action.payload,
    });
  }
  if (action.type === CLEAR) {
    return {};
  }
  if (action.type === CLEAR_PROJECT) {
    return Object.assign({}, state, {
      profile: null,
      repository: null,
      languages: null,
      scheme: null,
      organization: null,
      profileGithub: null,
      languagesGithub: null,
      schemeGithub: null,
      organizationGithub: null,
      repositoryGithub: null,
      application: null,
      database: null,
      databaseGithub: null,
      frontend: null,
      frontendGithub: null,
      backend: null,
      backendGithub: null,

    });
  }
  if (action.type === SET_PROFILE) {
    return Object.assign({}, state, {
      profile: action.payload,
    });
  }
  if (action.type === SET_LANGUAGES) {
    return Object.assign({}, state, {
      languages: action.payload,
    });
  }
  if (action.type === SET_LANGUAGE) {
    return Object.assign({}, state, {
      language: action.payload,
    });
  }
  if (action.type === SET_LANGUAGES_GITHUB) {
    return Object.assign({}, state, {
      languagesGithub: action.payload,
    });
  }
  if (action.type === SET_PROFILE_GITHUB) {
    return Object.assign({}, state, {
      profileGithub: action.payload,
    });
  }
  if (action.type === SET_SCHEME_GITHUB) {
    return Object.assign({}, state, {
      schemeGithub: action.payload,
    });
  }
  if (action.type === SET_MODE) {
    return Object.assign({}, state, {
      mode: action.payload,
    });
  }

  if (action.type === SET_THEME) {
    return Object.assign({}, state, {
      theme: action.payload,
    });
  }

  if (action.type === SET_BACKEND) {
    return Object.assign({}, state, {
      backend: action.payload,
    });
  }

  if (action.type === SET_FRONTEND) {
    return Object.assign({}, state, {
      frontend: action.payload,
    });
  }

  if (action.type === SET_DATABASE) {
    return Object.assign({}, state, {
      database: action.payload,
    });
  }

  if (action.type === SET_DATABASE_GITHUB) {
    return Object.assign({}, state, {
      databaseGithub: action.payload,
    });
  }

  if (action.type === SET_BACKEND_GITHUB) {

    return Object.assign({}, state, {
      backendGithub: action.payload,

    });
  }

  if (action.type === SET_FRONTEND_GITHUB) {
    return Object.assign({}, state, {
      frontendGithub: action.payload,
    });
  }

  if (action.type === SET_SCENE) {
    return Object.assign({}, state, {
      scene: action.payload,
    });
  }
  if (action.type === SET_APPLICATION) {
    return Object.assign({}, state, {
      application: action.payload,
    });
  }

  return state;
}

export default rootReducer;