import { useState } from "react";
import { connect } from "react-redux";
import { setLanguages, setUser } from '../../../../redux/actions'
import LayoutInputToggle from "../toggle/LayoutInputToggle";
import './index.scss'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser
}

interface DispatchProps {
    title: string,
    languages: any,
    icon: string,
    onSave: (data: any) => void
    defaultValue: any
}
type Props = DispatchProps

function LayoutInputTranslation(props: Props) {

    const [values, setValues] = useState(props.defaultValue)

    return (
        <LayoutInputToggle icon={props.icon} title={props.title} >
            <div className="Layout-input-translation" >

                <div className="Layout-input-translation-content">
                    {
                        props.languages.map((item: any, index: number) => {
                            return (
                                <div key={index} className="Layout-translation">
                                    <div className="Layout-translation-title">
                                        {item.name}
                                    </div>
                                    <div className="Layout-translation-input">

                                        <input type="text" value={values && values[item.code]} onChange={(event) => {
                                            setValues({ ...values, [item.code]: event.target.value })
                                            props.onSave({ ...values, [item.code]: event.target.value })
                                        }} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </LayoutInputToggle>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutInputTranslation)

