import { Base64 } from "js-base64";
import Get from "../functions/github/get";

export default class Profile {
    _user: any
    _organization: any
    _repository: any

    constructor(user: any, organization: any, repository: any) {
        this._user = user
        this._organization = organization
        this._repository = repository
    }

    async get() {
        return new Promise(async (resolve, reject) => {

            return await Get(this._user, this._organization, this._repository, 'profile.json').then((response: any) => {
                const data: any = response.data.content
                const decodedContent = Base64.decode(data)
                const configurationObject = JSON.parse(decodedContent)
                const clone = { ...configurationObject }
                resolve(clone)
            }).catch(async () => {

                const clone = {
                    "logotype": "",
                    "apellation": "",
                    "name": "",
                    "domain": "",
                    "author": ""
                }
                reject(clone)

            })
        })
    }
} ''