
import { connect } from "react-redux";
import { setLanguages, setScheme, setUser } from '../../redux/actions'
import Form from "./components/form";
import Toggle from "./components/toggle/LayoutInputToggle";

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser, setScheme
}

interface DispatchProps {
    sectionIndex: number,
    scheme: any,
    setScheme: (scheme: any) => void
}
type Props = DispatchProps

function LayoutLandingSplash(props: Props) {

    const sectionOriginal = props.scheme[props.sectionIndex]

    return (
        <>
            <Toggle icon="la la-hashtag" title="Global Section" >
                <Form
                    defaultValue={sectionOriginal}
                    inputs={
                        [
                            {
                                name: "id",
                                type: "text",
                                title: "Identify",
                                icon: "las la-image",
                            },
                            {
                                name: "icon",
                                type: "icon",
                                title: "Icon",
                                icon: "las la-image",
                            }
                        ]
                    }
                    onSubmit={(data: any) => {
                        props.scheme[props.sectionIndex].id = data.id
                        props.scheme[props.sectionIndex].icon = data.icon
                        props.setScheme(JSON.parse(JSON.stringify(props.scheme)))
                    }} />
            </Toggle>
            <Toggle icon="la la-spider" title="Search Engine Optimization" >

                <Form
                    defaultValue={sectionOriginal?.configuration}
                    inputs={
                        [
                            {
                                name: "name",
                                type: "translation",
                                title: "Name",
                                icon: "las la-heading",
                            },
                            {
                                name: "title",
                                type: "translation",
                                title: "Title",
                                icon: "las la-heading",
                            },
                            {
                                name: "description",
                                type: "translation",
                                title: "Description",
                                icon: "las la-heading",
                            },
                            {
                                name: "url",
                                type: "translation",
                                title: "Url",
                                icon: "las la-heading",
                            },
                            {
                                name: "glossary",
                                type: "translation",
                                title: "Glossary",
                                icon: "las la-heading",
                            }
                        ]
                    }
                    onSubmit={(data: any) => {
                        props.scheme[props.sectionIndex].configuration = data
                        props.setScheme(JSON.parse(JSON.stringify(props.scheme)))
                    }} />
            </Toggle>
            <Toggle icon="la la-compass" title="Navigation" >

                <Form
                    defaultValue={sectionOriginal?.configuration?.navigation || {}}
                    inputs={
                        [
                            {
                                name: "head",
                                type: "select",
                                title: "Head Menu",
                                icon: "las la-heading",
                                options: [
                                    {
                                        value: false,
                                        label: 'Ocultar'
                                    },
                                    {
                                        value: true,
                                        label: 'Mostrar'
                                    },

                                ]
                            },
                            {
                                name: "footer",
                                type: "select",
                                title: "Footer Menu",
                                icon: "las la-heading",
                                options: [
                                    {
                                        value: false,
                                        label: 'Ocultar'
                                    },
                                    {
                                        value: true,
                                        label: 'Mostrar'
                                    },

                                ]
                            },
                            {
                                name: "menu",
                                type: "select",
                                title: "Principal Menu",
                                icon: "las la-heading",
                                options: [
                                    {
                                        value: false,
                                        label: 'Ocultar'
                                    },
                                    {
                                        value: true,
                                        label: 'Mostrar'
                                    },

                                ]
                            },]
                    }
                    onSubmit={(data: any) => {
                        props.scheme[props.sectionIndex].configuration.navigation = data
                        props.setScheme(JSON.parse(JSON.stringify(props.scheme)))
                    }} />
            </Toggle>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutLandingSplash)
