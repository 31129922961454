
import { connect } from "react-redux";
import {
    setBackend, setBackendGithub, setDatabase,
    setDatabaseGithub, setFrontend,
    setFrontendGithub, setLanguage, setLanguages, setLanguagesGithub, setProfile, setProfileGithub, setScheme, setSchemeGithub
} from '../redux/actions'

import { useEffect, useState } from "react";
import { isEmpty } from "../functions/empty";
import Profile from './../repository/profile'
import LanguagesRepository from './../repository/languages'
import SchemeRepository from "../repository/scheme";
import Loading from "../componets/Loading/component";
import DatabaseReposoitory from "../repository/database";
import BackendReposoitory from "../repository/backend";
import FrontendReposoitory from "../repository/frontend";
// Connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguage, setProfile, setProfileGithub,
    setScheme, setSchemeGithub, setLanguages, setLanguagesGithub,
    setFrontend, setDatabase, setBackend,
    setFrontendGithub, setDatabaseGithub, setBackendGithub
}
const loaders: string[] = []
interface DispatchProps {
    user: any,
    organization: any,
    repository: any,
    scheme: any,
    language: any,
    theme: any,
    setProfile: (s: any) => void
    setLanguage: (s: any) => void
    setProfileGithub: (s: any) => void
    setScheme: (s: any) => void,
    clear: () => void,
    setSchemeGithub: (s: any) => void
    setLanguages: (s: any) => void
    setLanguagesGithub: (s: any) => void
    setFrontend: (s: any) => void
    setDatabase: (s: any) => void
    setBackend: (s: any) => void
    setFrontendGithub: (s: any) => void
    setDatabaseGithub: (s: any) => void
    setBackendGithub: (s: any) => void

}
type Props = DispatchProps
function Scenes(props: Props) {
    const [files, setFiles] = useState<any[]>([
        {
            bytes: 0,
            started: false,
            status: false,
            loadingText: 'Profile',
            repository: Profile,
            setter: props.setProfile,
            setterGithub: props.setProfileGithub
        },
        {
            bytes: 0,
            started: false,
            status: false,
            loadingText: 'Languages',
            repository: LanguagesRepository,
            setter: props.setLanguages,
            setterGithub: props.setLanguagesGithub
        },
        {
            started: false,
            status: false,
            bytes: 0,
            loadingText: 'Scheme',
            repository: SchemeRepository,
            setter: props.setScheme,
            setterGithub: props.setSchemeGithub
        },
        {
            bytes: 0,
            started: false,
            status: false,
            loadingText: 'Backend',
            repository: BackendReposoitory,
            setter: props.setBackend,
            setterGithub: props.setBackendGithub
        },
        {
            bytes: 0,
            started: false,
            status: false,
            loadingText: 'Database',
            repository: DatabaseReposoitory,
            setter: props.setDatabase,
            setterGithub: props.setDatabaseGithub
        },
        {
            bytes: 0,
            started: false,
            status: false,
            loadingText: 'Frontend',
            repository: FrontendReposoitory,
            setter: props.setFrontend,
            setterGithub: props.setFrontendGithub
        }
    ]);

    const loadFiles = async () => {
        if (props.user && props.organization && !isEmpty(props.organization) && props.repository && !isEmpty(props.repository)) {

            let filesIndex = 0;
            while (filesIndex < files.length) {
                const file: any = files[filesIndex]
                // Change status file
                files[filesIndex].started = true;
                setFiles(JSON.parse(JSON.stringify(files)))
                // Add another loading
                await new Promise(resolve => setTimeout(resolve, 200));
                // Sleep 1 second
                await new file.repository(props.user, props.organization.login, props.repository.name).get().then((data: any) => {
                    console.log('@@@@@@@@@@ guardamos!')
                    files[filesIndex].status = true;
                    files[filesIndex].bytes = JSON.stringify(files).length;
                    setFiles(JSON.parse(JSON.stringify(files)))
                    //
                    // Set data
                    file.setter(data)
                    file.setterGithub(data)
                }).catch(async (data: any) => {
                    console.log('@@@@@@@@@@ no guardamos!')
                    console.log('@@@@@@@@@@ no guardamos!')
                    console.log('@@@@@@@@@@ no guardamos!')
                    console.log('@@@@@@@@@@ no guardamos!')
                    console.log('@@@@@@@@@@ no guardamos!')
                    files[filesIndex].status = true;
                    files[filesIndex].bytes = 'NEW';
                    setFiles(JSON.parse(JSON.stringify(files)))
                    file.setter(data)
                    file.setterGithub(data)
                })

                filesIndex++
            }

            if (!props.language) {
                let language = {
                    'code': "es",
                    "name": "Español",
                }
                props.setLanguage(language)
            }

        }
    }
    useEffect(() => {
        if (props.organization) {
            setTimeout(() => {
                loadFiles()
            }, 1000);
        }
        else {
            alert('ERROR - No organization')
        }
    }, [])
    return <div style={{ margin: 10, marginBottom: 8 }}>
        {
            files.map((file: any, index: number) => {
                if (!file.started) return null
                return (<>

                    <Loading
                        key={index}
                        title={file.loadingText}
                        detail={file.bytes}
                        status={file.status}
                    />
                </>
                )
            })
        }
    </div>

}

export default connect(mapStateToProps, mapDispatchToProps)(Scenes)
function useStateWithCallback<T>(arg0: never[]): [any, any] {
    throw new Error("Function not implemented.");
}

