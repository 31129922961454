import { connect } from "react-redux";
import { clear, setOrganization, setUser } from '../../redux/actions'
import { useEffect, useState } from "react";
import { Octokit } from "@octokit/rest";
import Loading from '../Loading/component'
// Connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setUser, setOrganization, clear
}
// Function login props redux connect

interface DispatchProps {
    user: any,
    setOrganization: (s: any) => void
    clear: () => void
}
type Props = DispatchProps

function Organizations(props: Props) {
    // Setdata login redux

    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);

    // UseEffect first time
    useEffect(() => {

        setTimeout(() => {
            if (props.user) {
                showOrganizations(props.user)
            }
        }, 1000);

    }, [])

    const showOrganizations = async (user: string) => {

        //
        // Commit with octokit
        const octokit: any = await new Octokit({
            auth: user,
        });

        // Get username of autenticated user

        const orgs: any = await octokit.rest.orgs.listForAuthenticatedUser();

        setOrganizations(orgs.data)
        setLoading(false)
    }

    return (
        <>

            <div className="List">
                <div className="List-title">
                    Organizations
                </div>
                {
                    loading && <Loading title="Cargando organizaciones..." />
                }
                {
                    organizations && organizations.map((item: any, index) => (
                        <div className="List-item" key={index} onClick={() => {
                            props.setOrganization(item)
                        }}>
                            <b>{item.login}</b>
                            <i className="la la-arrow-right"></i>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Organizations)

