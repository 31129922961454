import { connect } from "react-redux";
import { setLanguages, setScheme, setUser } from '../../redux/actions'
import LayoutSectionComponentHeader from "./landing/header";
import './LayoutSectionComponent.scss'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser, setScheme
}

interface DispatchProps {
    componentIndex: number,
    sectionIndex: number,
    scheme: any,
    setScheme: (scheme: any) => void
    onSelectedComponent: () => void
}
type Props = DispatchProps

function LayoutSectionComponentLine(props: Props) {
    const type = props.scheme?.[props.sectionIndex]?.components?.[props.componentIndex]?.type
    const icon = ComponentsTypes[type]?.icon

    const renderComponentByType = () => {
        switch (type) {
            case 'Header':
                return <LayoutSectionComponentHeader sectionIndex={props.sectionIndex} componentIndex={props.componentIndex} />
            default:
                return 'foo';
        }
    }
    const component = props.scheme[props.sectionIndex].components[props.componentIndex]
    return (
        <>
            <div className="Layout-line" >
                <div className="Layout-line-title" onClick={
                    () => {
                        props.onSelectedComponent()
                    }
                }>
                    <i className={`Layout-line-title-icon la la-${icon}`} />
                    <div className="Layout-line-title-text">
                        {
                            component?.type
                        }
                    </div>
                    <div className="Layout-line-title-buttons">
                        <i className="Layout-line-title-buttons-button la la-trash" onClick={(event) => {
                            let newSchema = (JSON.parse(JSON.stringify(props.scheme)))
                            newSchema[props.sectionIndex].components.splice(props.componentIndex, 1)

                            event.stopPropagation()
                            props.setScheme(newSchema)
                        }} />
                        <i className="Layout-line-title-buttons-button la la-arrow-down" onClick={(event) => {
                            event.stopPropagation()

                            let newSchema = (JSON.parse(JSON.stringify(props.scheme)))
                            // Move section down
                            newSchema[props.sectionIndex].components.splice(props.componentIndex, 1)
                            newSchema[props.sectionIndex].components.splice(props.componentIndex + 1, 0, component)
                            props.setScheme(newSchema)
                        }} />
                        <i className="Layout-line-title-buttons-button la la-arrow-up" onClick={(event) => {
                            event.stopPropagation()

                            let newSchema = (JSON.parse(JSON.stringify(props.scheme)))
                            // Move section up
                            newSchema[props.sectionIndex].components.splice(props.componentIndex, 1)
                            newSchema[props.sectionIndex].components.splice(props.componentIndex - 1, 0, component)
                            props.setScheme(newSchema)

                        }} />

                    </div>
                </div>

            </div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSectionComponentLine)

const ComponentsTypes: any = {
    "Header": {
        "icon": "la la-header",
    },
    "Article": {
        "icon": "la la-align-left",
    },
    "Plans": {
        "icon": "la la-tag",
    },
    "Splash": {
        "icon": "la la-image",
    },
    "Reviews": {
        "icon": "la la-star-half-alt",
    },
    "Qualities": {
        "icon": "la la-list-alt",
    },
    "Contact": {
        "icon": "la la-envelope",
    },
    "Specifications": {
        "icon": "la la-list-alt",
    },
    "Downloads": {
        "icon": "la la-download",
    },
    "Definitions": {
        "icon": "la la-list-alt",
    },
    "Glossary": {
        "icon": "la la-list-alt",
    },
}