import { FormTabs, LayoutFormInputTypes, LayoutFormPreview } from "../form";
import LayoutInputToggle from "../toggle/LayoutInputToggle";
import './index.scss'
import ListItem from "./listItem";
interface DispatchProps {
    icon: string,
    title: string,
    defaultValue: any,
    inputs: LayoutFormInputTypes[],
    onSubmit: (data: any) => void,
    newDefaultvalue: any,
    previewParams?: LayoutFormPreview,
    tabs?: FormTabs[],

}

type Props = DispatchProps

function List(props: Props) {
    console.log('List:', props.defaultValue)
    return (
        <div className="Layout-list" >
            <LayoutInputToggle icon={props.icon} title={props.title} >
                <div >
                    {
                        props.defaultValue && props.defaultValue.length > 0 && props.defaultValue.map((lineData: any, index: number) => {
                            const value = lineData
                            const title =
                                (
                                    props.previewParams &&
                                    props.previewParams.title &&
                                    value?.[props.previewParams.title] &&
                                    value?.[props.previewParams.title])
                                || (
                                    props.previewParams &&
                                    props.previewParams.titleDefault)
                                || value?.title?.es || value.title
                            const bubble =
                                (
                                    props.previewParams &&
                                    props.previewParams.bubble &&
                                    value?.[props.previewParams.bubble] &&
                                    value?.[props.previewParams.bubble])
                                || (
                                    props.previewParams &&
                                    props.previewParams.bubbleDefault)
                                || undefined
                            const icon = (
                                props.previewParams &&
                                props.previewParams.icon &&
                                value?.[props.previewParams.icon] &&
                                value?.[props.previewParams.icon])
                                || (
                                    props.previewParams &&
                                    props.previewParams.iconDefault)
                                || value.icon || value.icon
                            return <ListItem key={index} title={title} icon={icon} bubble={bubble}
                                onRemove={() => {

                                    const newDefaultValue = props.defaultValue.filter((lineData: any) => lineData !== value)
                                    props.onSubmit(newDefaultValue)

                                }}
                                defaultValue={lineData}
                                inputs={props.inputs}
                                tabs={props.tabs}
                                onSubmit={(dataChanged: any) => {

                                    const newData = JSON.parse(JSON.stringify(props.defaultValue))
                                    newData[index] = dataChanged
                                    props.onSubmit(newData)

                                }} />

                        })
                    }
                    {
                        // Add new line in data
                        <div className="Layout-add" onClick={() => {
                            let newData = []
                            if (props.defaultValue) {
                                newData = JSON.parse(JSON.stringify(props.defaultValue))
                            }
                            console.log('newData:', newData)
                            newData.push(props.newDefaultvalue)
                            props.onSubmit(newData)

                        }}>
                            <i className="las la-plus" />
                        </div>
                    }

                </div>
            </LayoutInputToggle>
        </div>
    );
}

export default List;
