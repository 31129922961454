
import { useState } from "react";
import { connect } from "react-redux";
import Login from "../componets/Login/component";
import './index.scss';
import './../componets/Layout/index.scss';

import Page from "./page";
import WindowControls from "../componets/Toolbar";
import Side from "./side";

interface DispatchProps {
    user: any,
    application: any,
    profile: any,
    scene: string,
}

function Scenes(props: DispatchProps) {

    const [barDisplay, setBarDisplay] = useState<boolean>(true);
    const [widthWindow, setWidthWindow] = useState<string>('normal');
    const application = props.profile?.applications[props.application]
    const scene = application?.type
    console.log(':X:X:x', props)
    if (!props.user) {
        return <Login />
    }

    return (
        <div className="App">

            <WindowControls
                setBarDisplay={setBarDisplay}
                barDisplay={barDisplay}
                setWidthWindow={setWidthWindow}
                widthWindow={widthWindow}

            />
            <div className={`App-content ${barDisplay ? 'Bar-display' : 'Bar-hide'} App-width-${widthWindow}`}>
                <Side />
                {
                    scene === 'landing' && <Page />
                }
                {
                    scene === 'database' && <Page />
                }
            </div>

        </div>
    );
}

const mapStateToProps = (state: DispatchProps) => (state)
export default connect(mapStateToProps)(Scenes)
