import { connect } from "react-redux";
import { setApplication } from '../../../redux/actions'
import Backend from "../backend";
import Frontend from "../frontend";
import Landing from './../landing'
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setApplication
}

interface DispatchProps {
    profile: any,
    application: any,
    setApplication: (applicationId?: any) => void,
}
type Props = DispatchProps

function Applications(props: Props) {
    const applicationSelected = props.profile?.applications?.[props.application]
    return (
        <div className="Layout">
            {
                applicationSelected !== undefined && <>
                    <div className="Layout-back" onClick={() => { props.setApplication() }}>
                        <i className="la la-arrow-left" />
                        Volver
                    </div>
                    {
                        applicationSelected.type === 'landing' && <Landing />
                    }
                    {
                        applicationSelected.type === 'backend' && <Backend />
                    }
                    {
                        applicationSelected.type === 'frontend' && <Frontend />
                    }
                </>
            }
            {
                !applicationSelected && props.profile.applications.map((application: any, index: number) => {
                    return (
                        <div key={index} className="Layout-line">
                            <div className="Layout-line-title" onClick={() => props.setApplication(index)}>
                                <i className={`Layout-line-title-icon la la-home`} />
                                <div className="Layout-line-title-text">{application.name} </div>
                            </div>
                        </div>)
                })
            }
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Applications)
