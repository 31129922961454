import { connect } from "react-redux";
import markdown from "../../../functions/markdown";
import translate from "../../../functions/translation";
import { ProfileType } from "../../Profile/types";
import { Scheme } from "../types";
import { Section } from "../types";

// Connect redux
const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    profile: ProfileType,
    scheme: Scheme,
    section: Section,
    language: any,
    setSection?: (section: any) => void
}
type Props = DispatchProps

function Menu(props: Props) {
    if (props?.scheme) {
        return (
            <div className="Menu">

                <ul>
                    <li><a href="/" onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}><em></em><span dangerouslySetInnerHTML={{ __html: markdown(props.profile.logotype) }} /></a></li>
                    {
                        props?.scheme && props?.scheme.map((section: Section, index) => {
                            if (section?.configuration?.navigation?.menu && props.language && props.language.code) {
                                return (
                                    <li key={index} className={props.section === section ? 'active' : undefined}>
                                        <a
                                            href={'/' + props.language.code + '/' + translate(section.configuration.url, props.language)}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                props.setSection?.(section);
                                            }}>
                                            {translate(section.configuration.name, props.language)}
                                        </a>
                                    </li>
                                )
                            }
                            return null
                        })
                    }
                </ul >
            </div >
        );
    }
    return null
}

export default connect(mapStateToProps)(Menu)

