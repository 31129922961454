import React from "react";
import Selector from "./selector";
import { DictionaryOptions, FormTabs, LayoutFormPreview } from "../form";
import LayoutInputToggle from "../toggle/LayoutInputToggle";
import './index.scss'
import DictionaryItem from "./dictionaryItem";
interface DispatchProps {
    icon: string,
    title: string,
    defaultValue: any,
    options: {
        [key: string]: DictionaryOptions
    }
    tabs?: FormTabs[],
    onSubmit: (data: any) => void,
    previewParams?: LayoutFormPreview
}

type Props = DispatchProps

function Dictionary(props: Props) {
    return (
        <div className="Layout-dictionary" >
            <LayoutInputToggle icon={props.icon} title={props.title} >
                {
                    props.defaultValue && props.defaultValue.length > 0 && props.defaultValue.map((lineData: any, index: number) => {

                        const value = lineData

                        const title =
                            (
                                props.previewParams &&
                                props.previewParams.title &&
                                value?.[props.previewParams.title] &&
                                value?.[props.previewParams.title])
                            || (
                                props.previewParams &&
                                props.previewParams.titleDefault)
                            || value?.title?.es || value.title

                        const bubble =
                            (
                                props.previewParams &&
                                props.previewParams.bubble &&
                                value?.[props.previewParams.bubble] &&
                                value?.[props.previewParams.bubble])
                            || (
                                props.previewParams &&
                                props.previewParams.bubbleDefault)
                            || value?.bubble?.es || value.bubble
                        const icon = (
                            props.previewParams &&
                            props.previewParams.icon &&
                            value?.[props.previewParams.icon] &&
                            value?.[props.previewParams.icon])

                            || props.options[lineData.type] && props.options[lineData.type].icon || (
                                props.previewParams &&
                                props.previewParams.iconDefault) || value.icon

                        return <DictionaryItem
                            key={index}
                            tabs={props.tabs}
                            icon={icon}
                            title={title}
                            bubble={bubble}
                            onRemove={() => {

                                const newDefaultValue = props.defaultValue.filter((lineCompare: any) => lineCompare !== lineData)
                                props.onSubmit(newDefaultValue)

                            }}
                            defaultValue={lineData}

                            inputs={props.options[lineData.type]?.inputs} onSubmit={(dataChanged: any) => {

                                const newData = JSON.parse(JSON.stringify(props.defaultValue))
                                newData[index] = dataChanged
                                props.onSubmit(newData)

                            }}
                        />
                    })
                }
                <Selector options={props.options} onOptionSelected={(dataTypeString: string) => {
                    console.log('Data to new dic:', dataTypeString)
                    let newData = []
                    if (props.defaultValue) {
                        newData = JSON.parse(JSON.stringify(props.defaultValue))
                    }

                    newData.push({
                        type: dataTypeString
                    })

                    props.onSubmit(newData)
                }
                } />

            </LayoutInputToggle >
        </div >
    );
}

export default Dictionary;
