
export default async function Sitemap() {
    let content = `
    <? xml version = "1.0" encoding = "UTF-8" ?>
    <urlset
        xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
        <url>
            <loc>http://tattooble.com/</loc>
            <lastmod>2021-09-18T14:56:50+00:00</lastmod>
            <priority>1.00</priority>
        </url>

        <url>
            <loc>http://tattooble.com/es/404/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/home/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/advantages/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/contact/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/social/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/work/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/investor/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/prices/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/downloads/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/services/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/about/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
        <url>
            <loc>http://tattooble.com/es/sponsors/</loc>
            <lastmod>2021-09-18T14:56:00+00:00</lastmod>
            <priority>1.00</priority></url>
    </urlset>
    `
    return content
}
