import { useState } from "react";
import Form, { FormTabs, LayoutFormInputTypes } from "../form";
import LayoutInputToggle from "../toggle/LayoutInputToggle";
import './index.scss'
interface DispatchProps {
    icon: string,
    title: string,
    bubble?: string,
    defaultValue: any,
    inputs: LayoutFormInputTypes[],
    onSubmit: (data: any) => void,
    tabs?: FormTabs[],
    onRemove: () => void
}

type Props = DispatchProps

function DictionaryItem(props: Props) {
    const [tabSelected, setTabSelected] = useState<string | undefined>()
    return (
        <div className="Layout-dictionary-item" >
            <LayoutInputToggle
                icon={props.icon}
                title={props.title}
                tabs={props.tabs}
                bubble={props.bubble}
                tabSelected={tabSelected}
                onTabSelected={
                    (tabIdSelected: string) => {
                        setTabSelected(tabIdSelected)
                    }
                }
                onRemove={props.onRemove} >
                {
                    !props.inputs && <div>No inputs</div>
                }
                {
                    props.inputs &&

                    <Form
                        tab={tabSelected}

                        defaultValue={props.defaultValue}
                        inputs={props.inputs}
                        onSubmit={props.onSubmit} />}
            </LayoutInputToggle>

        </div>)

}

export default DictionaryItem;
