import { useState } from "react";
import { connect } from "react-redux";
import { setLanguages, setScheme, setUser } from '../../../redux/actions'
import './../components/index.scss'
import LayoutSectionLine from "../LayoutSectionLine";
import LayoutSection from "../LayoutSection";

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser, setScheme
}

interface DispatchProps {
    setLanguages: (s: any) => void
    clear: () => void
    scheme: any,
    setScheme: (newScheme: any) => void,
    title?: ""
}
type Props = DispatchProps

function Layout(props: Props) {

    const [sectionActive, setSectionActive] = useState<number | null>(null)

    return (
        <div className="Layout">
            <div className="Layout-counter">
                <b>{props.scheme.length}</b> sections
            </div>
            {
                sectionActive === null && props.scheme.map((item: any, index: number) => {
                    return (
                        <LayoutSectionLine key={index} sectionIndex={index} section={item} onSelectedSection={() => {
                            setSectionActive(index)
                        }} />
                    )
                }
                )
            }
            {
                sectionActive !== null && <LayoutSection sectionIndex={sectionActive} section={props.scheme[sectionActive]} onDeselectSection={() => {
                    setSectionActive(null)
                }} />
            }

            {sectionActive === null && <div className="Layout-add" onClick={() => {
                props.setScheme([...props.scheme, {
                }])
            }}>
                <i className="las la-plus"></i>
            </div>}

        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)

