import { Octokit } from "@octokit/rest";
import { Base64 } from 'js-base64';
import Get from "./get";

export default async function Save(
    userToken: string,
    owner: string,
    repo: string,
    path: string,
    content: any,
    branch: string = 'main',
    message: string = 'Commit',
    force: boolean = true
) {
    return new Promise(async (resolve, reject) => {

        const octokit = await new Octokit({
            auth: userToken,
        });

        let dataEncode: any = {}
        if (typeof content === 'object') {

            // Encode content:
            dataEncode = Base64.encode(JSON.stringify(content, null, 2))
        } else {
            dataEncode = content
        }

        await Get(userToken, owner, repo, path).then(async (response: any) => {
            console.log('Editamos archivo', path)
            // Save:
            if (force) {
                await octokit.repos.createOrUpdateFileContents({
                    owner,
                    repo,
                    path: path,
                    branch,
                    message: message,
                    sha: response.data.sha,
                    content: dataEncode
                })
            }
            else {
                console.log('No guardamos, ya que existe y tiene un force false')
            }
            resolve(true)
        }).catch(async () => {
            // If is json content
            console.log('Github New Save:', path)

            try {
                // Save:
                await octokit.repos.createOrUpdateFileContents({
                    owner,
                    repo,
                    path: path,
                    branch,
                    message: `Commit `,
                    content: dataEncode
                })
            }
            catch (error) {
                console.log('Github New Error:', error)
                reject()
            }

            resolve(true)
        })

    })
}