/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { connect } from "react-redux";
import { setProfile, } from '../../../redux/actions'
import Form from "../components/form";

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setProfile
}

interface DispatchProps {
    profile: any,
    application: number
    setProfile: (data: any) => void
}
type Props = DispatchProps

function BackendScheme(props: Props) {

    const application = props.profile?.applications?.[props.application];

    return (
        <>
            <Form
                defaultValue={{ screens: application.screens || [] }}
                inputs={
                    [
                        {
                            name: 'screens',
                            type: 'list',
                            inputs: [
                                {
                                    name: "authenticated",
                                    type: "select",
                                    title: "Authenticated",
                                    icon: "las la-key",
                                    options: [
                                        {
                                            value: 'anonymous',
                                            label: 'Anonymous'
                                        },
                                        {
                                            value: 'all',
                                            label: 'Anonymous'
                                        },
                                        {
                                            value: 'user',
                                            label: 'User'
                                        },
                                    ]
                                },

                                {
                                    name: 'name',
                                    type: 'text',
                                    title: 'Name',
                                    icon: 'las la-heading',
                                },
                                {
                                    name: 'icon',
                                    type: 'icon',
                                    title: 'Icon',
                                    icon: 'las la-shapes',
                                }
                                , {
                                    name: 'components',
                                    type: 'dictionary',
                                    tabs: [
                                        {
                                            icon: 'las la-cog',
                                            id: 'configuration',
                                            title: 'Configuration',
                                        }
                                    ],

                                    previewParams: {
                                        iconDefault: 'las la-heading',
                                        titleDefault: 'Name',
                                        title: 'name',
                                        icon: 'icon',
                                    },
                                    options: {
                                        calendar: {
                                            title: 'Calendar',
                                            icon: 'las la-heading',
                                            inputs: [
                                                {
                                                    tab: 'configuration',
                                                    name: 'name',
                                                    type: 'text',
                                                    title: 'Name',
                                                    icon: 'las la-heading',
                                                },
                                                {
                                                    tab: 'configuration',
                                                    name: 'icon',
                                                    type: 'icon',
                                                    title: 'Icon',
                                                    icon: 'las la-shapes',
                                                },
                                                {
                                                    name: 'method',
                                                    type: 'text',
                                                    title: 'Method',
                                                    icon: 'las la-shapes',
                                                }
                                            ]
                                        },
                                        table: {
                                            title: 'Table',
                                            icon: 'las la-heading',
                                            inputs: [
                                                {
                                                    tab: 'configuration',
                                                    name: 'name',
                                                    type: 'text',
                                                    title: 'Name',
                                                    icon: 'las la-heading',
                                                },
                                                {
                                                    tab: 'configuration',
                                                    name: 'icon',
                                                    type: 'icon',
                                                    title: 'Icon',
                                                    icon: 'las la-shapes',
                                                },
                                                {
                                                    name: "method",
                                                    type: "select",
                                                    title: "Method",
                                                    icon: "las la-key",
                                                    options: [
                                                        {
                                                            value: 'get',
                                                            label: 'Get'
                                                        },
                                                        {
                                                            value: 'post',
                                                            label: 'Post'
                                                        },
                                                        {
                                                            value: 'put',
                                                            label: 'Put'
                                                        },
                                                        {
                                                            value: 'delete',
                                                            label: 'Delete'
                                                        },
                                                    ]
                                                },
                                                {
                                                    name: 'path',
                                                    type: 'text',
                                                    title: 'Path',
                                                    icon: 'las la-url',
                                                }
                                            ]
                                        },
                                        form: {
                                            title: 'Form',
                                            icon: 'las la-pen',
                                            inputs: [
                                                {
                                                    tab: 'configuration',
                                                    name: 'name',
                                                    type: 'text',
                                                    title: 'Name',
                                                    icon: 'las la-heading',
                                                },
                                                {
                                                    tab: 'configuration',
                                                    name: 'icon',
                                                    type: 'icon',
                                                    title: 'Icon',
                                                    icon: 'las la-shapes',
                                                },
                                                {
                                                    name: "method",
                                                    type: "select",
                                                    title: "Method",
                                                    icon: "las la-key",
                                                    options: [
                                                        {
                                                            value: 'get',
                                                            label: 'Get'
                                                        },
                                                        {
                                                            value: 'post',
                                                            label: 'Post'
                                                        },
                                                        {
                                                            value: 'put',
                                                            label: 'Put'
                                                        },
                                                        {
                                                            value: 'delete',
                                                            label: 'Delete'
                                                        },
                                                    ]
                                                },
                                                {
                                                    name: 'components',
                                                    type: 'dictionary',
                                                    tabs: [
                                                        {
                                                            icon: 'las la-cog',
                                                            id: 'configuration',
                                                            title: 'Configuration',
                                                        }
                                                    ],
                                                    options: {
                                                        text: {
                                                            title: 'Text',
                                                            icon: 'las la-heading',
                                                            inputs: [

                                                                {
                                                                    name: 'name',
                                                                    type: 'text',
                                                                    title: 'Name',
                                                                    icon: 'las la-shapes',
                                                                }
                                                            ]
                                                        }
                                                    },

                                                    title: 'Inputs',
                                                    icon: 'las la-table'
                                                }
                                            ]
                                        }
                                    },

                                    title: 'Components',
                                    icon: 'las la-table'
                                }
                            ],
                            newDefaultvalue: {

                            },

                            previewParams: {
                                iconDefault: 'las la-heading',
                                titleDefault: 'Name',
                                title: 'name',
                                icon: 'icon',
                            },

                            title: 'Pantallas',
                            icon: 'las la-square'
                        }

                    ]
                }
                onSubmit={(data) => {
                    console.log('cambio:', data)
                    const copyProfile = JSON.parse(JSON.stringify(props.profile))
                    copyProfile.applications[props.application].screens = data.screens

                    props.setProfile(copyProfile)
                }} />

        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BackendScheme)
