import { useEffect, useRef } from 'react';

const useUnload = (callbackProps: any) => {
    const callback = useRef(callbackProps); // Init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
        callback.current = callbackProps;
    }, [callbackProps]);

    useEffect(() => {
        const onUnload = (...args: any) => callback.current?.(...args);

        window.addEventListener("beforeunload", onUnload);

        return () => window.removeEventListener("beforeunload", onUnload);
    }, []);
};

export default useUnload;