import { connect } from "react-redux";
import translate from "../../../functions/translation";
import { Translate } from "../types";

export interface GalleryComponent {
    type: 'Gallery',
    title?: Translate,
    description?: Translate,
    subtitle?: Translate,

    images?: {
        detail: Translate,
        url: string
    }[]
}

const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    settings: GalleryComponent
}
type Props = DispatchProps

function Article(props: Props) {
    if (props?.settings) {
        return (
            <div className="Gallery">
                {props.settings.title && <h4>{translate(props.settings.title)}</h4>}
                {props.settings.subtitle && <h5>{translate(props.settings.subtitle)}</h5>}
                {props.settings.description && <p>{translate(props.settings.description)}</p>}
                {props.settings.images && <ul>{
                    props.settings.images.map((image: any, index: number) => {
                        return (
                            <li key={index}>
                                <a href={image.url}>
                                    {translate(image.detail)}
                                </a>
                            </li>
                        )
                    })}
                </ul>}
            </div>
        );
    }
    return <div>asd</div>
}

export default connect(mapStateToProps)(Article)

