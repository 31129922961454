/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { connect } from "react-redux";
import { setProfile, } from '../../../redux/actions'
import Form, { LayoutFormInputTypeDictionaryOptions } from "../components/form";

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setProfile
}

interface DispatchProps {
    profile: any,
    application: number
    setProfile: (data: any) => void
}
type Props = DispatchProps

function BackendScheme(props: Props) {

    const actionsSchemeTypes: LayoutFormInputTypeDictionaryOptions = {
        insert: {
            title: 'Insert',
            icon: 'las la-heading',
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    title: 'Name',
                    icon: 'las la-heading',
                },
                {
                    name: 'icon',
                    type: 'icon',
                    title: 'Icon',
                    icon: 'las la-shapes',
                },
                {
                    name: 'table',
                    type: 'text',
                    title: 'Table',
                    icon: 'las la-shapes',
                }
            ]
        },
        query: {
            title: 'Query',
            icon: 'las la-heading',
            inputs: [
                {
                    tab: 'configuration',
                    name: 'name',
                    type: 'text',
                    title: 'Name',
                    icon: 'las la-heading',
                },
                {
                    tab: 'configuration',
                    name: 'icon',
                    type: 'icon',
                    title: 'Icon',
                    icon: 'las la-shapes',
                },
                {
                    tab: 'configuration',
                    name: 'variables',
                    type: 'list',

                    newDefaultvalue: {

                    },
                    previewParams: {
                        iconDefault: 'las la-heading',
                        titleDefault: 'variable',
                        title: 'name',
                        bubble: 'validation',
                        icon: 'icon',
                    },
                    title: 'Variables',
                    icon: 'las la-table',
                    inputs: [
                        {
                            name: 'name',
                            type: 'text',
                            title: 'Name',
                            icon: 'las la-heading',
                        },
                        {

                            name: "validation",
                            type: "select",
                            title: "Validation",
                            icon: "las la-image",
                            options: [
                                {
                                    value: 'exist',
                                    label: 'Exist'
                                },
                                {
                                    value: 'noEmpty',
                                    label: 'noEmpty'
                                },
                                {
                                    value: 'isString',
                                    label: 'isString'
                                },
                                {
                                    value: 'isNumber',
                                    label: 'Number'
                                }
                            ]
                        },
                    ]
                }
            ]
        },
        body: {

            title: 'Body Validation',
            icon: 'las la-heading',
            inputs: [
                {
                    tab: 'configuration',
                    name: 'name',
                    type: 'text',
                    title: 'Name',
                    icon: 'las la-heading',
                },
                {
                    tab: 'configuration',
                    name: 'icon',
                    type: 'icon',
                    title: 'Icon',
                    icon: 'las la-shapes',
                },
                {
                    tab: 'configuration',
                    name: 'variables',
                    type: 'list',

                    newDefaultvalue: {

                    },

                    previewParams: {
                        iconDefault: 'las la-heading',
                        titleDefault: 'variable',
                        title: 'name',
                        bubble: 'validation',
                        icon: 'icon',
                    },

                    title: 'Variables',
                    icon: 'las la-table',
                    inputs: [
                        {
                            name: 'name',
                            type: 'text',
                            title: 'Name',
                            icon: 'las la-heading',
                        },
                        {

                            name: "validation",
                            type: "select",
                            title: "Validation",
                            icon: "las la-image",
                            options: [
                                {
                                    value: 'exist',
                                    label: 'Exist'
                                },
                                {
                                    value: 'noEmpty',
                                    label: 'noEmpty'
                                },
                                {
                                    value: 'isString',
                                    label: 'isString'
                                },
                                {
                                    value: 'isNumber',
                                    label: 'Number'
                                }
                            ]
                        },
                    ]
                }
            ]
        },
        update: {
            title: 'Update',
            icon: 'las la-heading',
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    title: 'Name',
                    icon: 'las la-heading',
                },
                {
                    name: 'icon',
                    type: 'icon',
                    title: 'Icon',
                    icon: 'las la-shapes',
                },
                {
                    name: 'table',
                    type: 'text',
                    title: 'Table',
                    icon: 'las la-heading',
                }
            ]
        },

        select: {
            title: 'Select',
            icon: 'las la-heading',
            inputs: [
                {
                    tab: 'configuration',
                    name: 'name',
                    type: 'text',
                    title: 'Name',
                    icon: 'las la-heading',
                },
                {
                    tab: 'configuration',
                    name: 'icon',
                    type: 'icon',
                    title: 'Icon',
                    icon: 'las la-shapes',
                },
                {
                    tab: 'configuration',
                    name: 'table',
                    type: 'text',
                    title: 'Table',
                    icon: 'las la-heading',
                },
                {
                    tab: 'configuration',
                    name: 'where',
                    type: 'list',

                    newDefaultvalue: {

                    },

                    previewParams: {
                        iconDefault: 'las la-heading',
                        titleDefault: 'variable',
                        title: 'name',
                        bubble: 'model',
                        icon: 'icon',
                    },

                    title: 'Where',
                    icon: 'las la-table',
                    inputs: [
                        {
                            name: 'column',
                            type: 'text',
                            title: 'Column',
                            icon: 'las la-heading',
                        },
                        {
                            name: 'param',
                            type: 'text',
                            title: 'Parameter',
                            icon: 'las la-heading',
                        },
                        {

                            name: "model",
                            type: "select",
                            title: "Model",
                            icon: "las la-image",
                            options: [
                                {
                                    value: 'user',
                                    label: 'User'
                                },
                                {
                                    value: 'parent',
                                    label: 'parent'
                                }
                            ]
                        },
                    ]
                }

            ]
        }
    }

    let actionsScheme = {
        name: 'actions',
        type: 'dictionary',
        tabs: [
            {
                icon: 'las la-cog',
                id: 'configuration',
                title: 'Configuration',
            }
        ],
        previewParams: {
            iconDefault: 'las la-heading',
            titleDefault: 'Name',
            title: 'name',
            icon: 'icon',
            bubble: 'type',
        },

        options: actionsSchemeTypes,

        title: 'Actions',
        icon: 'las la-table'
    }
    // Map actionsScheme
    const actionsSchemeInfinite = Object.keys(actionsScheme.options).map((inputKey: string) => {

        const newType: any = actionsScheme.options[inputKey]
        newType.inputs[newType.inputs.length] = JSON.parse(JSON.stringify(actionsScheme))

        return newType
    })

    const actionsSchemeInfinite2 = Object.keys(actionsScheme.options).map((inputKey: string) => {
        const newType: any = actionsScheme.options[inputKey]

        newType.inputs[newType.inputs.length - 1] = Object.keys(newType.inputs[newType.inputs.length - 1].options).map((inputKey: string) => {
            const newTypeA: any = newType.inputs[newType.inputs.length - 1].options[inputKey]
            console.log('jsjs:', newTypeA)
            return newTypeA
        })
        return newType
    })

    // @ts-ignore
    actionsScheme.options = actionsSchemeInfinite
    console.log('jejejej:', actionsScheme)
    const application = props.profile?.applications?.[props.application];
    return (
        <>
            <Form
                defaultValue={{ namespaces: application.namespaces || [] }}
                inputs={
                    [
                        {
                            name: 'namespaces',
                            type: 'list',
                            tabs: [
                                {
                                    icon: 'las la-cog',
                                    id: 'configuration',
                                    title: 'Configuration',
                                }
                            ],
                            inputs: [
                                {
                                    tab: 'configuration',
                                    name: 'name',
                                    type: 'text',
                                    title: 'Name',
                                    icon: 'las la-heading',
                                },
                                {
                                    tab: 'configuration',
                                    name: 'icon',
                                    type: 'icon',
                                    title: 'Icon',
                                    icon: 'las la-shapes',
                                },
                                {
                                    name: 'methods',
                                    type: 'list',
                                    tabs: [
                                        {
                                            icon: 'las la-cog',
                                            id: 'configuration',
                                            title: 'Configuration',
                                        }
                                    ],
                                    inputs: [
                                        {
                                            tab: 'configuration',

                                            name: 'name',
                                            type: 'text',
                                            title: 'Name',
                                            icon: 'las la-heading',
                                        },
                                        {
                                            tab: 'configuration',

                                            name: 'icon',
                                            type: 'icon',
                                            title: 'Icon',
                                            icon: 'las la-shapes',
                                        },
                                        {
                                            name: "authenticated",
                                            type: "select",
                                            tab: 'configuration',
                                            title: "Authenticated",
                                            icon: "las la-key",
                                            options: [
                                                {
                                                    value: 'anonymous',
                                                    label: 'Anonymous'
                                                },
                                                {
                                                    value: 'all',
                                                    label: 'Anonymous'
                                                },
                                                {
                                                    value: 'user',
                                                    label: 'User'
                                                },
                                            ]
                                        },

                                        {
                                            tab: 'configuration',

                                            name: "method",
                                            type: "select",
                                            title: "Rest Method",
                                            icon: "las la-image",
                                            options: [
                                                {
                                                    value: 'get',
                                                    label: 'Get'
                                                },
                                                {
                                                    value: 'post',
                                                    label: 'Post'
                                                },
                                                {
                                                    value: 'put',
                                                    label: 'Put'
                                                },
                                                {
                                                    value: 'delete',
                                                    label: 'Delete'
                                                }
                                            ]
                                        },
                                        // @ts-ignore
                                        actionsScheme
                                    ],
                                    newDefaultvalue: {

                                    },

                                    previewParams: {
                                        iconDefault: 'las la-heading',
                                        titleDefault: 'name',
                                        title: 'name',
                                        icon: 'icon',
                                        bubble: 'method',

                                    },

                                    title: 'Métodos',
                                    icon: 'las la-table'
                                }

                            ],
                            newDefaultvalue: {

                            },

                            previewParams: {
                                iconDefault: 'las la-heading',
                                titleDefault: 'Name',
                                title: 'name',
                                icon: 'icon',
                            },

                            title: 'Espacios',
                            icon: 'las la-table'
                        }

                    ]
                }
                onSubmit={(data) => {
                    const newProfile = JSON.parse(JSON.stringify(props.profile))
                    newProfile.applications[props.application].namespaces = data.namespaces
                    props.setProfile(newProfile)
                }} />
            <Form
                defaultValue={{ tables: application.tables || [] }}
                inputs={
                    [
                        {
                            name: 'tables',
                            type: 'list',
                            inputs: [
                                {
                                    name: 'name',
                                    type: 'text',
                                    title: 'Name',
                                    icon: 'las la-heading',
                                },
                                {
                                    name: 'icon',
                                    type: 'icon',
                                    title: 'Icon',
                                    icon: 'las la-shapes',
                                },
                                {
                                    name: 'columns',
                                    type: 'dictionary',
                                    tabs: [
                                        {
                                            icon: 'las la-cog',
                                            id: 'configuration',
                                            title: 'Configuration',
                                        }
                                    ],
                                    options: {
                                        text: {
                                            title: 'Text',
                                            icon: 'las la-text-width',
                                            inputs: [

                                                {
                                                    name: 'name',
                                                    type: 'text',
                                                    title: 'Name',
                                                    icon: 'las la-shapes',
                                                }
                                            ]
                                        },
                                        email: {
                                            title: 'Email',
                                            icon: 'las la-at',
                                            inputs: [

                                                {
                                                    name: 'name',
                                                    type: 'text',
                                                    title: 'Name',
                                                    icon: 'las la-shapes',
                                                }
                                            ]
                                        },
                                        date: {
                                            title: 'Date',
                                            icon: 'las la-at',
                                            inputs: [

                                                {
                                                    name: 'name',
                                                    type: 'text',
                                                    title: 'Name',
                                                    icon: 'las la-shapes',
                                                }
                                            ]
                                        },
                                        datetime: {
                                            title: 'Datetime',
                                            icon: 'las la-at',
                                            inputs: [

                                                {
                                                    name: 'name',
                                                    type: 'text',
                                                    title: 'Name',
                                                    icon: 'las la-shapes',
                                                }
                                            ]
                                        },
                                        id: {
                                            title: 'ID',
                                            icon: 'las la-at',
                                            inputs: [

                                                {
                                                    name: 'name',
                                                    type: 'text',
                                                    title: 'Name',
                                                    icon: 'las la-shapes',
                                                }
                                            ]
                                        },
                                        uuid: {
                                            title: 'UUID',
                                            icon: 'las la-at',
                                            inputs: [

                                                {
                                                    name: 'name',
                                                    type: 'text',
                                                    title: 'Name',
                                                    icon: 'las la-shapes',
                                                }
                                            ]
                                        }
                                    },

                                    previewParams: {
                                        iconDefault: 'las la-heading',
                                        titleDefault: 'Name',
                                        title: 'name',
                                        icon: 'icon',
                                    },
                                    title: 'Columns',
                                    icon: 'las la-table'
                                }
                            ],
                            newDefaultvalue: {

                            },

                            previewParams: {
                                iconDefault: 'las la-heading',
                                titleDefault: 'Name',
                                title: 'name',
                                icon: 'icon',
                            },

                            title: 'Tables',
                            icon: 'las la-table'
                        }

                    ]
                }
                onSubmit={(data) => {
                    console.log('cambio:', data)
                    const copyProfile = JSON.parse(JSON.stringify(props.profile))
                    copyProfile.applications[props.application].tables = data.tables

                    props.setProfile(copyProfile)
                }} />

        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BackendScheme)
