import { ProfileType } from "../../componets/Profile/types"

export default async function Readme(profile: ProfileType) {
    console.log('readme:', profile)
    let year = new Date().getFullYear()
    let content = '# `Landing` de **' + profile.apellation + '**'
    content += '\n**' + profile.apellation + '** © ' + year + ' - Todos los derechos reservados '

    content += '\n\n\nRepositorio de **' + profile.apellation + '** con la configuración'
        + 'del **Landing**, la renderización del HTML y su despliegue automático.'
        + '\nLa motivación de este Landing es generar un HTML compilado para ser '
        + 'desplegado de la forma más económica posible. El objetivo de este '
        + 'repositorio no es generar un landing programático.'
    content += '\n## Despliegue'
    content += '\nEl despliegue es realizado por Github Actions al editar'
        + ' el archivo **versions.json** en Google Cloud App Service clonando'
        + ' la carpeta **build** y ejecutando el archivo **app.yaml**.'

    // Markdown table:
    content += '\n| Variable | Descripción |'
    content += '\n| --- | --- |'
    content += '\n| `GOOGLE_CLOUD_PROJECT_ID_STRING` | String del proyecto |'
    content += '\n| `GOOGLE_CLOUD_KEYS_JSON` | JSON con las claves de Google Storage |'
    content += '\n## Objectivo'
    content += '\nConsiderando las limitaciones de la tipología de construcción enumeramos las ventajas específicas.'
    // Markdown table:
    content += '\n| Ventaja | Descripción |'
    content += '\n| --- | --- |'
    content += '\n| `Velocidad de Renderizado` | No existe formula más óptima de renderizado |'
    content += '\n| `Costes del Alojamiento` | Coste del procesado inexistente |'
    content += '\n| `Versionado` | Despliegue dependiente del versionado |'
    console.log(content)
    return content
}