import { connect } from "react-redux";
import translate from "../../../functions/translation";
import { Translate } from "../types";

export interface QualitiesComponent {
    type: 'Qualities',
    title?: Translate,
    description?: Translate,
    subtitle?: Translate,

    qualities?: {
        title: Translate,
        description: Translate
        icon: string

    }[]
}

const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    settings: QualitiesComponent
}
type Props = DispatchProps

function Plans(props: Props) {
    if (props?.settings) {
        return (
            <div className="Qualities">
                {props.settings.title && <h5>{translate(props.settings.title)}</h5>}
                {props.settings.subtitle && <h6>{translate(props.settings.subtitle)}</h6>}
                {props.settings.description && <p>{translate(props.settings.description)}</p>}
                {props.settings.qualities && Array.isArray(props.settings.qualities) && <ul>{
                    props.settings.qualities.map((item: any, index: number) => {
                        return (
                            <li key={index}>
                                <i className={'la la-' + item.icon}></i>
                                <b>
                                    {translate(item.title)}
                                </b>

                                <p>
                                    {translate(item.description)}
                                </p>

                            </li>
                        )
                    })}
                </ul>}
            </div>
        );
    }
    return null
}

export default connect(mapStateToProps)(Plans)

