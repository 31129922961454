import { connect } from "react-redux";
import translate from "../../../functions/translation";
import { Translate } from "../types";

export interface ArticleComponent {
    type: 'Article',
    title?: Translate,
    description?: Translate,
    subtitle?: Translate,
    thumb?: boolean,
    background: 'video' | 'image',
    links?: {
        text: Translate,
        url: string
    }[]
    specifications?: {
        title: Translate,
        icon: string
    }[]
}

const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    settings: ArticleComponent
}
type Props = DispatchProps

function Article(props: Props) {
    if (props?.settings) {
        return (
            <div className="Article">
                <div className="image">
                    {
                        props.settings.thumb && <div className="thumb"></div>
                    }
                    {
                        props.settings?.background && props.settings?.background === 'video' && <video autoPlay loop muted >
                            <source src="/graphics/videos/example.mp4" type="video/mp4" />
                        </video>
                    }
                </div>
                <div className="content">
                    {props.settings.title && <h3>{translate(props.settings.title)}</h3>}
                    {props.settings.subtitle && <h4>{translate(props.settings.subtitle)}</h4>}
                    {props.settings.description && <p>{translate(props.settings.description)}</p>}
                    {props.settings.links && <ul>{
                        props.settings.links.map((link: any, index: number) => {
                            return (
                                <li key={index}>
                                    <a href={link.url}>
                                        {translate(link.text)}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>}
                    {props.settings.specifications && <ol>{
                        props.settings.specifications.map((link: any, index: number) => {
                            return (
                                <li key={index}>
                                    <i className={link.icon}></i>
                                    <b>
                                        {translate(link.title)}
                                    </b>
                                </li>
                            )
                        })}
                    </ol>}
                </div>
            </div>
        );
    }
    return <div>asd</div>
}

export default connect(mapStateToProps)(Article)

