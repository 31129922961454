import { connect } from "react-redux";
import './styles.css'

import { ToastContainer } from 'react-toastify';
import './toast.css';
import Loading from "../Loading/component";

// Connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
}

interface DispatchProps {
    loading: boolean,
    addReadme: () => void
    saveConfiguration: () => void
    saveRender: () => void
}
type Props = DispatchProps

function ControlsButtons(props: Props) {

    return (<>
        <div className="Controls">

            {
                props.loading ? <Loading title="Guardando en Github" /> : <>

                    <button className="Controls-save" onClick={props.addReadme}>
                        ADD README
                    </button>

                    <button className="Controls-save" onClick={props.saveConfiguration}>
                        SAVE CONFIGURATION
                    </button>

                    <button className="Controls-save" onClick={props.saveRender}>
                        RENDER
                    </button>

                </>}

        </div>
        <ToastContainer />
    </>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ControlsButtons)

