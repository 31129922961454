import { connect } from "react-redux";

const mapStateToProps = (state: any, ownProps: any) => (state)

// Function login props redux connect

interface DispatchProps {
    mode: string,
    children: any,
    type: string
}
type Props = DispatchProps

function Layers(props: Props) {

    if (props.mode === 'production') {
        return (
            <>
                {
                    props.children
                }
            </>
        );
    }
    else {
        return (
            <div className="Component" >
                <div className="Component-title">{props.type}</div>
                <div className="Component-Layout">
                    {
                        props.children
                    }
                </div>

            </div>
        );
    }

}

export default connect(mapStateToProps,)(Layers)

