import { connect } from "react-redux";
import { clear, setOrganization, setRepository, setUser } from '../../redux/actions'
import { useEffect, useState } from "react";
import { Octokit } from "@octokit/rest";
import Loading from "../Loading/component";

// Connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setUser, clear, setRepository, setOrganization
}
// Function login props redux connect

interface DispatchProps {
    user: any,
    repository: any,
    organization: any,
    setUser: (s: string) => void
    setRepository: (s: any) => void
    setOrganization: (s?: any) => void
    clear: () => void,
    modeLoad?: string
}
type Props = DispatchProps

function Repositories(props: Props) {
    // Setdata login redux

    const [repositories, setRepositories] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);

    // UseEffect first time
    useEffect(() => {

        setTimeout(() => {
            showRepositories(props.organization)
        }, 1000);

    }, [])

    const showRepositories = async (organization: any) => {
        console.log('organization', props)
        const octokit = await new Octokit({
            auth: props.user,
        });
        if (props.modeLoad === 'all') {
            // Get all respositories user is invited
            await octokit.paginate(
                octokit.repos.listForAuthenticatedUser,
                {
                    'per_page': 100,
                },
                (response: any, done: any) => {
                    let repositoriesAll: any = repositories.concat(response.data)
                    repositoriesAll.sort(function (repositoriesA: any, repositoriesB: any) {
                        let keyA = new Date(repositoriesA.created_at),
                            keyB = new Date(repositoriesB.created_at);
                        if (keyA < keyB) return 1;
                        if (keyA > keyB) return -1;
                        return 0;
                    });
                    setRepositories(repositoriesAll)
                    setLoading(false)
                    return response.data
                }

            )
        }
        else {
            await octokit.paginate(
                octokit.repos.listForOrg,
                {
                    org: organization.login,
                    'per_page': 100,
                },
                (response: any, done: any) => {
                    let repositoriesAll: any = repositories.concat(response.data)
                    // Order by name
                    repositoriesAll.sort(function (repositoryA: any, repositoryB: any) {
                        let keyA = new Date(repositoryA.created_at),
                            keyB = new Date(repositoryB.created_at);
                        // Compare the 2 dates
                        if (keyA < keyB) return 1;
                        if (keyA > keyB) return -1;
                        return 0;
                    });
                    setRepositories(repositoriesAll)
                    setLoading(false)
                    return response.data
                }

            )
        }

    }

    return (
        <>
            <div className="List">
                <div className="List-title">
                    Repositorios
                </div>
                {
                    loading && <Loading title="Cargando repositorios..." />
                }
                {
                    repositories && repositories.map((item: any, index) => (
                        <div className="List-item" key={index} onClick={async () => {
                            console.log(item)
                            props.setOrganization(item.owner)
                            props.setRepository(item)
                            // Reload all page
                        }}>
                            <b>{item.name}</b><i className="la la-arrow-right"></i>
                        </div>
                    ))

                }
            </div>

        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Repositories)

