import { ProfileType } from "../../componets/Profile/types";
import { Section } from "../../componets/Scheme/types"
import translate from "../translation";

export default async function Skeleton(profile: ProfileType, section: Section, child: any = '') {
    // Random string
    const random = Math.random().toString(36).substring(2, 15);

    let html = '';
    html += '<!DOCTYPE html>'
    html += '<html lang="en">'
    html += '<head>'

    // Browser
    html += '<meta charset="UTF-8">'
    html += '<meta name="viewport" content="width=device-width, initial-scale=1">'

    // Seo
    html += '<title>' + translate(section.configuration.title) + ' - ' + profile.apellation + '</title>'
    html += '<meta name="description" content="' + translate(section.configuration.description) + '">'
    html += '<meta name="keywords" content="' + translate(section.configuration.glossary) + '">'
    html += '<meta name="robots" content="index, follow">'
    html += '<meta name="author" content="' + profile.author + '">'
    html += '<meta name="copyright" content="' + (profile.author) + '">'

    // Styles
    html += '<link rel="stylesheet" href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css">'
    html += '<link rel="stylesheet" href="/styles/theme.css?cache=' + random + '">'
    html += '<link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css">'
    html += '<script src="https://unpkg.com/aos@next/dist/aos.js"></script>'

    // Og
    html += '<meta property="og:title" content="' + translate(section.configuration.title) + '">'
    html += '<meta property="og:site_name" content="' + (profile.domain) + '">'
    html += '<meta property="og:url" content="https://' + profile.domain + '/' + translate(section.configuration.url) + '">'
    html += '<meta property="og:description" content="' + translate(section.configuration.description) + '">'
    html += '<meta property="og:type" content="website">'
    // <meta property="og:image" content=" https://tattooble.com/static/open.jpg">

    // Scripts
    html += '<script async="" src="https://call.chatra.io/chatra.js"></script>'
    html += '</head>'
    html += '<body>'
    html += child
    html += '</body>'
    html += '</html>'

    return html
}

