import { useState } from "react";
import { connect } from "react-redux";
import { setLanguages, setScheme, setUser } from '../../redux/actions'
import LayoutSectionComponent from "./LayoutSectionComponent";
import LayoutSectionComponentLine from "./LayoutSectionComponentLine";
import './LayoutSectionComponents.scss'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser, setScheme
}

interface DispatchProps {
    sectionIndex: number,
    section: any,
    onDeselectSection: () => void,
    scheme: any,
    setScheme: (scheme: any) => void
}
type Props = DispatchProps

function LayoutSectionComponents(props: Props) {
    const [componentSelected, setComponentSelected] = useState<any>(null)
    const [showSelectorNewComponent, setShowSelectorNewComponent] = useState<boolean>(false)
    if (componentSelected !== null) {
        return <LayoutSectionComponent componentIndex={componentSelected} sectionIndex={props.sectionIndex} onDeselectComponent={() => {
            setComponentSelected(null)
        }} />
    }
    const addNewComponent = (componentType: string) => {
        const newComponent = {
            type: componentType,

        }
        const newScheme = JSON.parse(JSON.stringify(props.scheme))
        if (!newScheme[props.sectionIndex].components) newScheme[props.sectionIndex].components = []
        newScheme[props.sectionIndex].components.push(newComponent)
        props.setScheme(newScheme)
    }
    const componentsTypes: string[] = [
        'Header', 'Article', 'Contact', 'Definitions', 'Downloads', 'Glossary', 'Images', 'Plans', 'Qualities', 'Reviews', 'Specifications', 'Splash']
    return (
        <div className="Layout-section-components ">
            {
                props.scheme[props.sectionIndex].components && props.scheme[props.sectionIndex].components.map((item: any, index: number) => {
                    return <LayoutSectionComponentLine key={index} sectionIndex={props.sectionIndex} componentIndex={index} onSelectedComponent={
                        () => {
                            setComponentSelected(index)
                        }
                    } />
                })
            }
            {!showSelectorNewComponent && <div className="Layout-add" onClick={() => {
                setShowSelectorNewComponent(true)
            }}>
                <i className="las la-plus"></i>
            </div>}
            {
                showSelectorNewComponent && <div className="Layout-add-selector">

                    <div className="Layout-add-selector-types">
                        {
                            componentsTypes.map((item: string, index: number) => {
                                return <div className="Layout-add-selector-type" key={index} onClick={() => {
                                    addNewComponent(item)
                                    setShowSelectorNewComponent(false)
                                }}>
                                    {item}
                                </div>
                            })}

                    </div>
                </div>
            }
        </div>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSectionComponents)

