import './index.css'

// Function login props redux connect

interface DispatchProps {
    title: string,
    detail?: string,
    status?: boolean
}
type Props = DispatchProps

function Loading(props: Props) {
    // Setdata login redux

    return (
        <div className={`Loading ${props.status ? 'Loading-finish' : ''}`}>
            <div className="Loading-bar" />
            <i className="las la-check" />
            <div className="Loading-title">
                {props.title}
            </div>
            <div className="Loading-detail">
                s{props.detail}
            </div>

        </div>
    );
}

export default Loading

