import {
  CLEAR,
  CLEAR_PROJECT,
  SET_APPLICATION,
  SET_BACKEND,
  SET_BACKEND_GITHUB,
  SET_DATABASE,
  SET_DATABASE_GITHUB,
  SET_FRONTEND,
  SET_FRONTEND_GITHUB,
  SET_LANGUAGE,
  SET_LANGUAGES,
  SET_LANGUAGES_GITHUB,
  SET_MODE,
  SET_ORGANIZATION,
  SET_PROFILE,
  SET_PROFILE_GITHUB,
  SET_REPOSITORY,
  SET_SCENE,
  SET_SCHEME,
  SET_SCHEME_GITHUB,
  SET_THEME,
  SET_USER

} from '../constants/actions';

export function setOrganization(payload) {
  return {
    type: SET_ORGANIZATION,
    payload
  };
}
export function setUser(payload) {
  return {
    type: SET_USER,
    payload
  };
}

export function setScheme(payload) {

  return {
    type: SET_SCHEME,
    payload
  };
}

export function setRepository(payload) {
  return {
    type: SET_REPOSITORY,
    payload
  };
}

export function setLanguage(payload) {
  return {
    type: SET_LANGUAGE,
    payload
  };
}

export function clear(payload) {
  return {
    type: CLEAR,
    payload
  };
}

export function clearProject(payload) {
  return {
    type: CLEAR_PROJECT,
    payload
  };
}

export function setProfile(payload) {
  return {
    type: SET_PROFILE,
    payload
  };
}

export function setLanguages(payload) {
  return {
    type: SET_LANGUAGES,
    payload
  };
}

export function setLanguagesGithub(payload) {
  return {
    type: SET_LANGUAGES_GITHUB,
    payload
  };
}

export function setProfileGithub(payload) {
  return {
    type: SET_PROFILE_GITHUB,
    payload
  };
}

export function setSchemeGithub(payload) {
  return {
    type: SET_SCHEME_GITHUB,
    payload
  };
}

export function setMode(payload) {
  return {
    type: SET_MODE,
    payload
  };
}

export function setTheme(payload) {
  return {
    type: SET_THEME,
    payload
  };
}
export function setDatabase(payload) {
  return {
    type: SET_DATABASE,
    payload
  };
}

export function setDatabaseGithub(payload) {
  return {
    type: SET_DATABASE_GITHUB,
    payload
  };
}

export function setBackend(payload) {
  return {
    type: SET_BACKEND,
    payload
  };
}

export function setBackendGithub(payload) {
  return {
    type: SET_BACKEND_GITHUB,
    payload
  };
}

export function setFrontend(payload) {
  return {
    type: SET_FRONTEND,
    payload
  };
}

export function setFrontendGithub(payload) {
  return {
    type: SET_FRONTEND_GITHUB,
    payload
  };
}

export function setScene(payload) {
  return {
    type: SET_SCENE,
    payload
  }
}

export function setApplication(payload) {
  return {
    type: SET_APPLICATION,
    payload
  }
}