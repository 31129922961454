
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
    children,
    ...props
}) => {
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body
    useEffect(() => {
        // Execute setIframeHeight('iframe') per 1 second
        let intervaling = setInterval(() => {
            setIframeHeight('iframe')
        }, 1000)
        return () => {
            clearInterval(intervaling)
        }
    }, [contentRef])

    return (<div className='iframe' onLoad={(event) => {

        let iframe = event.target
        console.log('Iframe')
        if (iframe) {
            // Here you can make the height, I delete it first, then I make it again
            setIframeHeight('iframe')
        }

    }}>
        <iframe title='Landing' id='iframe' {...props} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe></div>
    )
}

function setIframeHeight(iframeId) {
    let ifrm = document.getElementById(iframeId);
    let iframeDocument = ifrm.contentDocument ? ifrm.contentDocument :
        ifrm.contentWindow.document;

    // Element with class Page
    let page = iframeDocument.getElementsByClassName('Page')[0];
    ifrm.style.visibility = 'hidden';
    // IE opt. for bing/msn needs a bit added or scrollbar appears
    ifrm.style.height = page.offsetHeight + 10 + "px";
    ifrm.style.visibility = 'visible';
}