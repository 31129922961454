import { connect } from "react-redux";
import translate from "../../../functions/translation";
import { Translate } from "../types";

export interface PlansComponent {
    type: 'Plans',
    title?: Translate,
    description?: Translate,
    subtitle?: Translate,

    plans?: {
        name: Translate,
        description: Translate
        information: Translate
        price: string
        url: string,
        button: Translate
        details: {
            icon: string,
            text: Translate
        }[]
    }[]
}

const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    settings: PlansComponent
}
type Props = DispatchProps

function Plans(props: Props) {
    console.log(props)
    if (props?.settings) {
        return (
            <div className="Plans">
                {props.settings.title && <h5>{translate(props.settings.title)}</h5>}
                {props.settings.subtitle && <h6>{translate(props.settings.subtitle)}</h6>}
                {props.settings.description && <p>{translate(props.settings.description)}</p>}
                {props.settings.plans && Array.isArray(props.settings.plans) && <ul>{
                    props.settings.plans.map((item: any, index: number) => {
                        return (
                            <li key={index}>
                                <b>
                                    {translate(item.name)}
                                </b>
                                <em>
                                    {translate(item.price)}
                                </em>
                                <p>
                                    {translate(item.description)}
                                </p>
                                <i>
                                    {translate(item.information)}
                                </i>
                                <ul>
                                    {item.details && item.details.map((detail: any, index: number) => {
                                        return (
                                            <li key={index}>
                                                <i className={'la la-' + detail.icon}></i>
                                                <span>
                                                    {translate(detail.text)}
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <a href={item.url}>
                                    {translate(item.button)}
                                </a>
                            </li>
                        )
                    })}
                </ul>}
            </div>
        );
    }
    return <div>asd</div>
}

export default connect(mapStateToProps)(Plans)

