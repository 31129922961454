import { ProfileType } from "../../componets/Profile/types"
import { Scheme } from "../../componets/Scheme/types"
import translate from "../translation"

export default async function Yaml(profile: ProfileType, scheme: Scheme, languages: any) {
    let content = 'runtime: python27\n'
    content += 'api_version: 1\n'
    content += 'threadsafe: false\n'
    content += 'service: ' + profile.service + '\n'
    content += 'handlers:\n'
    content += '  - url: /\n'
    content += '    static_files: sections/Home/es.html\n'
    content += '    upload: sections/Home/es.html\n'
    content += '  - url: /styles/theme.css\n'
    content += '    static_files: statics/styles/theme.css\n'
    content += '    upload: statics/styles/theme.css\n'
    let languageIndex = 0;
    while (languages.length > languageIndex) {
        let sectionIndex = 0;
        const language = languages[languageIndex];
        while (scheme.length > sectionIndex) {
            const section: any = scheme[sectionIndex];
            content += '  - url: /' + language.code + '/' + translate(section.configuration.url, language.code) + '\n'
            content += '    static_files: sections/' + section.id + '/' + language.code + '.html\n'
            content += '    upload: sections/' + section.id + '/' + language.code + '.html\n'
            sectionIndex++;
        }
        languageIndex++;
    }

    return content;
}
