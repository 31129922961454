type Props = {
    theme?: string
}
function Styles(props: Props) {

    return (
        <>
            <link rel="stylesheet" href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css" />

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Libre+Barcode+128+Text&display=swap" // eslint-disable-line
                rel="stylesheet" />
            <link rel="stylesheet" href="/components/Reset/index.css" />
            <link rel="stylesheet" href="/components/Builder/index.css" />
            <link rel="stylesheet" href="/components/Global/index.css" />

            <link rel="stylesheet" href="/components/Section/index.css" />
            <link rel="stylesheet" href="/components/Componenter/index.css" />

            <link rel="stylesheet" href="/components/Article/index.css" />
            <link rel="stylesheet" href="/components/Contact/index.css" />
            <link rel="stylesheet" href="/components/Definitions/index.css" />
            <link rel="stylesheet" href="/components/Downloads/index.css" />
            <link rel="stylesheet" href="/components/Footer/index.css" />
            <link rel="stylesheet" href="/components/Ruler/index.css" />
            <link rel="stylesheet" href="/components/Glossary/index.css" />
            <link rel="stylesheet" href="/components/Head/index.css" />
            <link rel="stylesheet" href="/components/Header/index.css" />
            <link rel="stylesheet" href="/components/Images/index.css" />
            <link rel="stylesheet" href="/components/Menu/index.css" />
            <link rel="stylesheet" href="/components/Plans/index.css" />
            <link rel="stylesheet" href="/components/Qualities/index.css" />
            <link rel="stylesheet" href="/components/Reviews/index.css" />
            <link rel="stylesheet" href="/components/Specifications/index.css" />
            <link rel="stylesheet" href="/components/Splash/index.css" />
            {
                props.theme && <link rel="stylesheet" href={`/components/Reset/${props.theme}.css`} />
            }
        </>
    );
}

export default Styles;
