import { connect } from "react-redux";
import translate from "../../../functions/translation";
import { Scheme } from "../types";
import { Section } from "../types";
import { setLanguage } from '../../../redux/actions'
import { ProfileType } from "../../Profile/types";

// Connect redux
const mapStateToProps = (state: any, rest: any) => (state)

const mapDispatchToProps = {
    setLanguage
}

interface DispatchProps {
    scheme: Scheme
    profile: ProfileType
    languages: any
    language: any
    setSection?: (section: any) => void
    setLanguage?: (language: any) => void
}
type Props = DispatchProps

function Layers(props: Props) {

    if (props?.scheme) {
        return (
            <div className="Head">
                <ul>
                    {
                        props?.scheme.map((section: Section, index) => {
                            if (section?.configuration?.navigation?.head && props.language && props.language.code) {

                                return (
                                    <li key={index}>
                                        <a
                                            href={'/' + props.language.code + '/' + translate(section.configuration.url, props.language)}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                props.setSection?.(section);
                                            }}>
                                            {translate(section.configuration.name)}
                                        </a>
                                    </li>
                                )
                            }
                            return null
                        })
                    }
                    {
                        props.profile.chatra &&
                        <li >
                            <span>
                                <i className="las la-comments"></i>
                                {translate(props.profile.chatra.button)}
                            </span>
                        </li>}
                    <li>
                        <span>
                            <i className="las la-globe"></i>

                            {props.language && props.language.name && props.language.name}
                            <i className="la la-angle-down"></i>
                        </span>
                        <ul>
                            {
                                props.languages && Array.isArray(props.languages) && props.languages.map((language: any, index: number) => {
                                    if (language.code) {
                                        return (
                                            <li key={index}>
                                                <a
                                                    href={'/' + language.code + '/'}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        props.setLanguage?.(language);
                                                    }}>
                                                    {language.name}
                                                </a>
                                            </li>
                                        )
                                    }
                                    return null
                                })

                            }
                        </ul>
                    </li>
                </ul>
            </div>
        );
    }
    return null
}

export default connect(mapStateToProps, mapDispatchToProps)(Layers)

