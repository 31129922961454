import { connect } from "react-redux";
import translate from "../../../functions/translation";
import { Translate } from "../types";

export interface SplashComponent {
    type: 'Splash',
    title?: Translate,
    description?: Translate,
    subtitle?: Translate,
    background?: 'video' | 'image',
    links?: {
        text: Translate,
        url: string
    }[]
}

// Connect redux
const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    settings: SplashComponent
}
type Props = DispatchProps

function Splash(props: Props) {
    if (props?.settings) {
        return (
            <div className="Splash">
                {
                    props.settings?.background && props.settings?.background === 'video' && <video autoPlay loop muted >
                        <source src="/graphics/videos/example.mp4" type="video/mp4" />
                    </video>
                }

                {props.settings.title && <h4>{translate(props.settings.title)}</h4>}
                {props.settings.subtitle && <h5>{translate(props.settings.subtitle)}</h5>}
                {props.settings.description && <p>{translate(props.settings.description)}</p>}
                {props.settings.links && <ul>{
                    props.settings.links.map((link: any, index: number) => {
                        return (
                            <li key={index}>
                                <a href={link.url}>
                                    {translate(link.text)}
                                </a>
                            </li>
                        )
                    })}
                </ul>}
            </div>
        );
    }
    return <div>asd</div>
}

export default connect(mapStateToProps)(Splash)

