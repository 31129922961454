import './styles.css'

// Function login props redux connect
import { clear, setMode, setScene, setTheme } from '../../redux/actions'
import { connect } from 'react-redux';
import useUnload from './window-event';

const mapDispatchToProps = {
    clear, setMode, setTheme, setScene
}
const mapStateToProps = (state: any, ownProps: any) => (state)

interface DispatchProps {
    setScene: (s: any) => void
    scene: string
    clear: () => void,
    setMode: (mode: string) => void,
    mode: string,
    profileGithub: any,
    profile: any,
    languages: any,
    languagesGithub: any,
    scheme: any,
    schemeGithub: any,
}
type Props = DispatchProps

function Selector(props: Props) {

    useUnload((event: any) => {

        let alert = false;

        if (JSON.stringify(props.profileGithub) !== JSON.stringify(props.profile)) {
            alert = true
        }
        if (JSON.stringify(props.languagesGithub) !== JSON.stringify(props.languages)) alert = true
        if (JSON.stringify(props.schemeGithub) !== JSON.stringify(props.scheme)) alert = true

        if (alert) {
            event.preventDefault();

            return event.returnValue = 'Are you sure you want to close?';

        }
    })
    return (
        <>
            <div className="Selector">
                <ul>
                    <li className={props.scene === 'profile' ? 'active' : undefined} onClick={() => {
                        props.setScene('profile')
                    }}>
                        <i className="las la-tools"></i>
                        {

                            JSON.stringify(props.profileGithub) !== JSON.stringify(props.profile) && <b></b>
                        }

                    </li>
                    <li className={props.scene === 'applications' ? 'active' : undefined} onClick={() => {
                        props.setScene('applications')
                    }}>
                        <i className="las la-atom"></i>
                        {
                            JSON.stringify(props.schemeGithub) !== JSON.stringify(props.scheme) && <b></b>
                        }
                    </li>

                    <li className={props.scene === 'deploy' ? 'active' : undefined} onClick={() => {
                        props.setScene('deploy')
                    }}>
                        <i className="las la-save"></i>

                    </li>
                </ul>
            </div>
            {/* <Header title={information[props.scene].title}
                description={information[props.scene].description}
            /> */}
        </>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Selector)
const information: any = {
    'profile': {
        title: 'Perfil',
        description: 'Configuración General'
    },
    'languages': {
        title: 'Idiomas',
        description: 'Sistema de Traducciones'
    },
    'landing': {
        title: 'Landing',
        description: 'Contenido y mapa del sitio'
    },
    'deploy': {
        title: 'Almacenamiento',
        description: 'Sistema de Despliegue'
    },
    'backend': {
        title: 'Servidor',
        description: 'Configuración del servidor'
    },
    'frontend':
    {
        title: 'Aplicación',
        description: 'Configuración de la aplicación'
    },
    'database':
    {
        title: 'Base de Datos',
        description: 'Configuración de la base de datos'
    }
}