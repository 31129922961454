import { connect } from "react-redux";

export interface DownloadsComponent {
    type: 'Downloads',
    links?: {
        type: string,
        url: string
    }[]
}

// Connect redux
const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    settings: DownloadsComponent
}
type Props = DispatchProps

function Article(props: Props) {
    if (props?.settings) {
        return (
            <div className="Dowloads">
                {props.settings.links && <ul>{
                    props.settings.links.map((link: any, index: number) => {
                        return (
                            <li key={index}>
                                <a href={link.url}>
                                    {(link.type)}
                                </a>
                            </li>
                        )
                    })}
                </ul>}
            </div>
        );
    }
    return <div>asd</div>
}

export default connect(mapStateToProps)(Article)

