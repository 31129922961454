import { Base64 } from "js-base64";
import Get from "../functions/github/get";

export default class FrontendReposoitory {
    _user: any
    _organization: any
    _repository: any

    constructor(user: any, organization: any, repository: any) {
        this._user = user
        this._organization = organization
        this._repository = repository
    }

    async get() {
        return new Promise(async (resolve, reject) => {

            return await Get(this._user, this._organization, this._repository, 'frontend.json').then((response: any) => {
                const data: any = response.data.content
                const decodedContent = Base64.decode(data)
                const configurationObject = JSON.parse('{"languages":' + decodedContent + '}')
                const clone = configurationObject.languages
                resolve(clone)
            }).catch(async () => {

                const clone: any = []
                reject(clone)
            })
        })
    }
}