import { connect } from "react-redux";
import { setLanguages, setScheme, setUser } from '../../../../redux/actions'
import Form from "../../components/form";

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser, setScheme
}

interface DispatchProps {
    componentIndex: number,
    sectionIndex: number,
    scheme: any,
    setScheme: (scheme: any) => void
}
type Props = DispatchProps

function LayoutLandingHeader(props: Props) {

    return (
        <>
            <Form
                defaultValue={props.scheme[props.sectionIndex].components[props.componentIndex]}
                inputs={
                    [
                        {
                            name: "background",
                            type: "select",

                            title: "Background",

                            icon: "las la-image",
                            options: [
                                {
                                    value: 'video',
                                    label: 'Video'
                                },
                                {
                                    value: 'image',
                                    label: 'Image'
                                }
                            ]
                        },
                        {
                            name: "title",
                            type: "translation",
                            title: "Title",
                            icon: "las la-heading",
                        },
                        {
                            name: "subtitle",
                            type: "translation",
                            title: "Subtítulo",
                            icon: "las la-heading",

                        },
                        {
                            name: "description",
                            type: "translation",
                            title: "Descripción",
                            icon: "las la-heading",
                        },
                    ]
                }
                onSubmit={(data) => {
                    console.log('Dat:', data)
                    props.scheme[props.sectionIndex].components[props.componentIndex] = data
                    props.setScheme(JSON.parse(JSON.stringify(props.scheme)))
                }} />

        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutLandingHeader)
