import React from "react";
import { DictionaryOptions } from "../form";
import './index.scss'
interface DispatchProps {
    options: {
        [key: string]: DictionaryOptions
    }
    onOptionSelected: (data: any) => void,

}

type Props = DispatchProps

function Dictionary(props: Props) {
    const [showSelector, setShowSelector] = React.useState(false)
    return (
        <>
            <div className="Layout-add" onClick={() => {
                setShowSelector(true)
            }}>
                <i className="la la-plus" />
            </div>
            {
                showSelector &&

                <div className="Layout-selector" >
                    {
                        Object.keys(props.options).map((optionType: string, index: number) => {

                            const option = props.options[optionType]
                            return <div className="Layout-selector-item" key={index} onClick={() => {
                                setShowSelector(false)
                                props.onOptionSelected(optionType)
                            }}>
                                {option.title}
                            </div>

                        })
                    }
                </div>}
        </>
    );
}

export default Dictionary;
