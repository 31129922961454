import { connect } from "react-redux";
import translate from "../../../functions/translation";
import { Translate } from "../types";

export interface GlossaryComponent {
    type: 'Glossary',
    title?: Translate,
    description?: Translate,
    subtitle?: Translate,

    terms?: {
        title: Translate,
        subtitle: Translate,
        description: Translate,
    }[]
}

const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    settings: GlossaryComponent
}
type Props = DispatchProps

function Article(props: Props) {
    if (props?.settings) {
        return (
            <div className="Glossary">
                {props.settings.title && <h3>{translate(props.settings.title)}</h3>}
                {props.settings.subtitle && <h4>{translate(props.settings.subtitle)}</h4>}
                {props.settings.description && <p>{translate(props.settings.description)}</p>}
                {props.settings.terms && <ul>{
                    props.settings.terms.map((term: any, index: number) => {
                        return (
                            <li key={index}>
                                <span>{translate(term.title)}</span>
                                <em>{translate(term.subtitle)}</em>
                                <p>{translate(term.description)}</p>
                            </li>
                        )
                    })}
                </ul>}
            </div>
        );
    }
    return <div>asd</div>
}

export default connect(mapStateToProps)(Article)

