import React from "react";
import LayoutInputSelect from "../select/LayoutInputSelect";
import LayoutInputText from "../text";
import LayoutInputIcon from "../icon";
import LayoutInputTranslation from "../translation/LayoutInputTranslation";
import LayoutInputList from "../list";
import Dictionary from "../dictionary";
import ReactJson from 'react-json-view'

interface LayoutFormInputType {
    tab?: string,
    icon: string,
    name: string,
    title: string,
}
interface LayoutFormInputSelectOption {
    value: string | boolean | number,
    label: string
}
export interface LayoutFormPreview {
    iconDefault?: string,
    titleDefault?: string,
    icon?: string,
    title?: string,
    bubble?: string,
    bubbleDefault?: string
}
interface LayoutFormInputTypeText extends LayoutFormInputType { type: "text", }
interface LayoutFormInputTypeTranslation extends LayoutFormInputType { type: "translation", }
interface LayoutFormInputTypeIcon extends LayoutFormInputType { type: "icon" }
interface LayoutFormInputTypeSelect extends LayoutFormInputType { type: "select", options: LayoutFormInputSelectOption[] }
interface LayoutFormInputTypeList extends LayoutFormInputType {
    previewParams?: LayoutFormPreview,
    tabs?: FormTabs[]
    newDefaultvalue: any, type: "list", inputs: LayoutFormInputTypes[]
}
export interface FormTabs {
    icon: string,
    id: string,
    title: string,
}
export interface DictionaryOptions {
    title: string,
    icon: string,
    inputs: LayoutFormInputTypes[],

}
export interface LayoutFormInputTypeDictionaryOptions {
    [key: string]: DictionaryOptions
}
export interface LayoutFormInputTypeDictionary extends LayoutFormInputType {
    tabs?: FormTabs[]
    type: "dictionary",
    options: LayoutFormInputTypeDictionaryOptions
    previewParams?: LayoutFormPreview,
}
export type LayoutFormInputTypes =
    LayoutFormInputTypeText |
    LayoutFormInputTypeIcon |
    LayoutFormInputTypeSelect |
    LayoutFormInputTypeTranslation |
    LayoutFormInputTypeList | LayoutFormInputTypeDictionary

interface DispatchProps {
    tab?: string,
    defaultValue: any,
    inputs: LayoutFormInputTypes[],
    onSubmit: (data: any) => void
}

type Props = DispatchProps

function Form(props: Props) {
    const jsonViewer = false
    const dataOriginal = props.defaultValue
    const [showJsonViewer, setShowJsonViewer] = React.useState(false)
    return (
        <div className="Layout-form" >
            {
                jsonViewer && (showJsonViewer ?
                    <div className="Layout-form-jsonViewer" >

                        <div className="Layout-form-jsonViewer-close" onClick={() => {
                            setShowJsonViewer(false)
                        }}>
                            cerrar
                        </div>
                        <ReactJson src={dataOriginal} />

                    </div> :
                    <div className="Layout-form-jsonViewer-close" onClick={() => {
                        setShowJsonViewer(true)
                    }}>
                        abrir
                    </div>)
            }
            {
                props.inputs.map((input: LayoutFormInputTypes, index) => {
                    const type = input.type
                    const name = input.name
                    const value = dataOriginal && dataOriginal?.[name]
                    let icon = input.icon
                    if (type === "icon" && value) {
                        icon = value

                    }

                    // Miramos si pertenece a una seccion
                    if (input.tab || props.tab) {
                        if (input.tab !== props.tab) {
                            return null;
                        }

                    }

                    const title = input.title
                    return <React.Fragment key={index}>
                        {
                            type === "select" &&
                            <LayoutInputSelect
                                icon={icon}
                                title={title}
                                defaultValue={value}
                                options={input.options}
                                onSave={(dataChanged: any) => {
                                    props.onSubmit({ ...dataOriginal, [name]: dataChanged })

                                }} />
                        }
                        {
                            type === "text" &&
                            <LayoutInputText
                                icon={icon}
                                title={title}
                                defaultValue={value}
                                onSave={(dataChanged: any) => {
                                    props.onSubmit({ ...dataOriginal, [name]: dataChanged })
                                }} />
                        }
                        {
                            type === "icon" &&
                            <LayoutInputIcon
                                icon={icon}
                                title={title}
                                defaultValue={value}
                                onSave={(dataChanged: any) => {
                                    console.log('Icono cambiado:', dataChanged)
                                    props.onSubmit({ ...dataOriginal, [name]: dataChanged })
                                }} />
                        }
                        {
                            type === "translation" &&
                            <LayoutInputTranslation
                                icon={icon}
                                title={title}
                                defaultValue={value}
                                onSave={(dataChanged: any) => {
                                    props.onSubmit({ ...dataOriginal, [name]: dataChanged })
                                }} />
                        }
                        {
                            type === "list" &&
                            <LayoutInputList
                                icon={icon}
                                title={title}
                                inputs={input.inputs}
                                tabs={input.tabs}
                                defaultValue={value}
                                previewParams={input.previewParams}
                                newDefaultvalue={input.newDefaultvalue}
                                onSubmit={(dataChanged: any) => {
                                    props.onSubmit({ ...dataOriginal, [name]: dataChanged })
                                }} />
                        }
                        {
                            type === "dictionary" &&
                            <Dictionary
                                icon={icon}
                                title={title}
                                options={input.options}
                                tabs={input.tabs}
                                defaultValue={value}
                                previewParams={input.previewParams}

                                onSubmit={(dataChanged: any) => {
                                    props.onSubmit({ ...dataOriginal, [name]: dataChanged })
                                }} />
                        }
                    </React.Fragment>
                })
            }

        </div>
    );
}

export default Form;
