/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { useState } from "react";
import { Provider, connect } from "react-redux";
import Robots from "../../functions/generator/robots";
import Sitemap from "../../functions/generator/sitemap";
import Yaml from "../../functions/generator/yaml";
import Get from "../../functions/github/get";
import Save from "../../functions/github/save";
import { Section } from "../Scheme/types";
import './styles.css'
import ReactDOM from 'react-dom'
import Header from "../Scheme/Header/component";
import Head from "../Scheme/Head/component";
import Footer from "../Scheme/Footer/component";
import Article from "../Scheme/Article/component";
import Menu from "../Scheme/Menu/component";
import Plans from "../Scheme/Plans/component";
import Contact from "../Scheme/Contact/component";
import Definitions from "../Scheme/Definitions/component";
import Downloads from "../Scheme/Downloads/component";
import Glossary from "../Scheme/Glossary/component";
import Gallery from "../Scheme/Gallery/component";
import Qualities from "../Scheme/Qualities/component";
import Reviews from "../Scheme/Reviews/component";
import Specifications from "../Scheme/Specifications/component";
import Splash from "../Scheme/Splash/component";
import ControlsButtons from './controlsButtons'
import { toast } from 'react-toastify';
import './toast.css';

import { store } from './../../redux/store'
import { Buffer } from "buffer"

import { setLanguages, setLanguagesGithub, setProfile, setProfileGithub, setScheme, setSchemeGithub } from '../../redux/actions'
import { Base64 } from "js-base64";
import Readme from "../../functions/generator/readme";
import Skeleton from "../../functions/generator/skeleton";
let format = require('pretty');

// Connect redux
const mapStateToProps = (state: any) => (state)

const mapDispatchToProps = {
    setProfile, setProfileGithub, setScheme, setSchemeGithub, setLanguages, setLanguagesGithub
}

// Function login props redux connect

interface DispatchProps {
    database: any,
    frontend: any,
    backend: any,
    user: any,
    organization: any,
    repository: any,
    scheme: any,
    languages: any,
    profile: any,
    setProfile: (s: any) => void
    setProfileGithub: (s: any) => void
    setScheme: (s: any) => void
    setSchemeGithub: (s: any) => void
    setLanguages: (s: any) => void
    setLanguagesGithub: (s: any) => void
}
type Props = DispatchProps

function Layers(props: any) {
    const [loading, setLoading] = useState(false);
    // Setdata login redux
    const saveConfiguration = async () => {
        setLoading(true);
        await Save(props.user, props.organization.login, props.repository.name, 'profile.json', props.profile,);
        await Save(props.user, props.organization.login, props.repository.name, 'languages.json', props.languages,);
        await Save(props.user, props.organization.login, props.repository.name, 'scheme.json', props.scheme,);
        await Save(props.user, props.organization.login, props.repository.name, 'database.json', props.database,);
        await Save(props.user, props.organization.login, props.repository.name, 'frontend.json', props.frontend,);
        await Save(props.user, props.organization.login, props.repository.name, 'backend.json', props.backend,);

        await loadFiles()
        setLoading(false);

    }

    const loadFiles = async () => {
        // Cargamos profile:
        await Get(props.user, props.organization.login, props.repository.name, 'profile.json').then((response: any) => {
            const data: any = response.data.content
            const decodedContent = Base64.decode(data)
            const configurationObject = JSON.parse(decodedContent)
            const clone = { ...configurationObject }
            props.setProfile(clone)
            props.setProfileGithub(clone)
            setLoading(false)
        }).catch(async () => {
            alert('error')

        })
        await Get(props.user, props.organization.login, props.repository.name, 'languages.json').then((response: any) => {
            const data: any = response.data.content
            const decodedContent = Base64.decode(data)
            const configurationObject = JSON.parse('{"languages":' + decodedContent + '}')
            const clone = { ...configurationObject }
            props.setLanguages(clone.languages)
            props.setLanguagesGithub(clone.languages)
            setLoading(false)
        }).catch(async () => {
            alert('error')

        })
        await Get(props.user, props.organization.login, props.repository.name, 'scheme.json').then((response: any) => {
            const data: any = response.data.content
            const decodedContent = Base64.decode(data)
            const configurationObject = JSON.parse('{"sections":' + decodedContent + '}')
            const clone = { ...configurationObject }
            props.setScheme(clone.sections)
            props.setSchemeGithub(clone.sections)
            setLoading(false)
        }).catch(async () => {
            alert('error')
        })
    }

    const addReadme = async () => {

        const content = btoa(await Readme(props.profile));
        await Save(props.user, props.organization.login, props.repository.name, `readme.md`, content);

    }

    const saveRender = async () => {
        setLoading(true);

        // Pasamos por cada sección:
        let sectionIndex = 0;
        while (sectionIndex < props.scheme.length) {
            const section: Section = props.scheme[sectionIndex];
            const span = document.createElement('span');
            ReactDOM.render(
                <Provider store={store}>
                    <Head section={section} />
                    <Menu section={section} />
                    {
                        section?.components?.map((item: any) => {
                            if (item?.type === 'Header') return <Header settings={item} />
                            if (item?.type === 'Article') return <Article settings={item} />
                            if (item?.type === 'Plans') return <Plans settings={item} />
                            if (item?.type === 'Contact') return <Contact settings={item} />
                            if (item?.type === 'Definitions') return <Definitions settings={item} />
                            if (item?.type === 'Downloads') return <Downloads settings={item} />
                            if (item?.type === 'Glossary') return <Glossary settings={item} />
                            if (item?.type === 'Gallery') return <Gallery settings={item} />
                            if (item?.type === 'Qualities') return <Qualities settings={item} />
                            if (item?.type === 'Reviews') return <Reviews settings={item} />
                            if (item?.type === 'Specifications') return <Specifications settings={item} />
                            if (item?.type === 'Splash') return <Splash settings={item} />

                            return null;
                        })
                    }
                    <Footer section={section} />

                </Provider>, span);
            let result = await Skeleton(props.profile, section, span.innerHTML)
            let iframeHtml = format(result)
            const buff = Buffer.from(iframeHtml, 'utf-8');

            // Decode buffer as Base64
            const base64 = buff.toString('base64');
            let toas: any = toast(
                `build/sections/${section.id}/es.html`, {
                autoClose: false,
                position: 'bottom-left',
                delay: 0
            }

            )
            console.log('Vamos')
            await Save(props.user, props.organization.login, props.repository.name, `build/sections/${section.id}/es.html`, base64, 'main', 'Section ' + section.id + ' saved').then(() => {

                setTimeout(() => {
                    toast.dismiss(toas)
                }, 700);
            }).catch((error: any) => {
                console.log('error', error)
                setTimeout(() => {
                    toast.dismiss(toas)
                }, 700);
            })

            sectionIndex++;
        }

        const yaml = btoa(await Yaml(props.profile, props.scheme, props.languages));
        await Save(props.user, props.organization.login, props.repository.name, `build/app.yaml`, yaml, 'main', 'Add app.yaml file for Google Storage');
        const sitemap = btoa(await Sitemap());
        await Save(props.user, props.organization.login, props.repository.name, `build/sitemap.xml`, sitemap, 'main', 'Add sitemap.xml file');
        const robots = btoa(await Robots());
        await Save(props.user, props.organization.login, props.repository.name, `build/robots.txt`, robots, 'main', 'Add robots.txt file');
        // Generate css:
        const libraries = ['Reset', 'Global', 'Head', 'Article', 'Menu', 'Footer', 'Header', 'Plans', 'Contact', 'Definitions', 'Downloads', 'Glossary', 'Images', 'Qualities', 'Reviews', 'Specifications', 'Splash']
        let cssContent = ''
        let libraryIndex: number = 0;
        while (libraryIndex < libraries.length) {
            const library = libraries[libraryIndex];
            const content: any = await getContent('/components/' + library + '/index.css');
            if (content) {
                cssContent += content;

            }
            libraryIndex++;
        }
        console.log('csssssss', cssContent)
        cssContent = btoa(cssContent);

        Save(props.user, props.organization.login, props.repository.name, 'build/statics/styles/theme.css', cssContent, 'main', 'Add CSS generated');

        // Get versions
        await Get(props.user, props.organization.login, props.repository.name, 'versions.json').then((response: any) => {
            const data: any = response.data.content
            const decodedContent = Base64.decode(data)
            const configurationObject = JSON.parse('{"versions":' + decodedContent + '}')
            const clone = { ...configurationObject }
            // Get las version
            const lastVersion = clone.versions[clone.versions.length - 1] + 1
            clone.versions.push(lastVersion)
            const versions = btoa(JSON.stringify(clone.versions))
            Save(props.user, props.organization.login, props.repository.name, 'versions.json', versions, 'main', 'Add versions (' + lastVersion + ')');
        }).catch(async () => {

            const versions = btoa('[1]')
            Save(props.user, props.organization.login, props.repository.name, 'versions.json', versions, 'main', 'Add new versions (1)');
        })

        // Get html of iframe
        setLoading(false);
    }
    // Deep clone props.motor with object assign

    return (<ControlsButtons loading={loading} addReadme={addReadme} saveConfiguration={saveConfiguration} saveRender={saveRender} />
    );
}
function getContent(path: any) {
    return new Promise((resolve, reject) => {
        let request: any = new XMLHttpRequest();
        request.open('GET', path, true);
        request.send(null);
        request.onreadystatechange = function () {
            if (request.readyState === 4 && request.status === 200) {
                let type = request.getResponseHeader('Content-Type');
                if (type.indexOf("text") !== 1) {
                    resolve(request.responseText)
                }
            }
        }
        // Error XMLHttpRequest
        request.onerror = function () {
            reject(Error("Network Error"))
        }

    })
}
export default connect(mapStateToProps, mapDispatchToProps)(Layers)

