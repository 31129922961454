import { connect } from "react-redux";
import { setLanguages, setUser } from '../../../../redux/actions'
import { FormTabs } from "../form";
import './index.scss'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser
}

interface DispatchProps {
    title: string,
    bubble?: string,
    icon: string,
    toggle: boolean,
    onChangeToggle: () => void,
    onOpenToggle: () => void,
    onCloseToggle: () => void,
    onRemove?: () => void,
    tabs?: FormTabs[],
    tabSelected?: string,
    onTabSelected?: (tabId?: string) => void,
}
type Props = DispatchProps

function LayoutInputTitle(props: Props) {

    return (
        <div className="Layout-title" onClick={() => {
            props.onTabSelected?.()
            props.onChangeToggle()
        }}>
            {
                props.icon && <div className="Layout-title-icon"><i className={props.icon}></i></div>
            }
            <div className="Layout-title-text">{typeof props.title === 'string' && props.title}</div>
            <div className="Layout-title-space"></div>
            {typeof props.bubble === 'string' && props.bubble && <div className="Layout-title-bubble">{props.bubble}</div>}

            <div className="Layout-title-buttons">

                {
                    props.onRemove &&
                    <div className="Layout-title-buttons-icon" onClick={() => {
                        props.onRemove?.()
                    }}>
                        <i className="la la-trash" />
                    </div>
                }
                {
                    props.tabs && props.tabs.map((tabItem: FormTabs, tabItemKey: number) => {
                        return (
                            <div
                                key={tabItemKey}
                                className={`Layout-title-buttons-icon ${tabItem.id === props.tabSelected ? 'Layout-title-buttons-icon-active' : ''}`}
                                onClick={(event: any) => {
                                    event.stopPropagation()
                                    if (tabItem.id === props.tabSelected) {
                                        props.onTabSelected?.()
                                        props.onOpenToggle()
                                    }
                                    else {
                                        props.onTabSelected?.(tabItem.id)
                                        props.onOpenToggle()
                                    }

                                }}>
                                <i className={tabItem.icon} />
                            </div>
                        )
                    })
                }
                <div className="Layout-title-buttons-icon" >
                    {
                        !props.toggle &&
                        <i className="la la-angle-down" />

                    }
                    {
                        props.toggle &&
                        <i className="la la-angle-up" />

                    }</div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutInputTitle)

