
import { connect } from "react-redux";
import './index.scss';

import Menu from "./menu";
import Loader from "./loader";
import Organizations from "../componets/Organizations/component";
import Repositories from "../componets/Repositories/component";

// Connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
interface DispatchProps {
    organization: any,
    repository: any,
    scheme: any,
    profile: any,
    language: any,
    database: any,
    frontend: any,
    backend: any,
}
type Props = DispatchProps
function Side(props: Props) {

    return (

        <div className="Bar">
            <div className="Bar-content">
                {
                    !props.organization ? <>
                        <Organizations />
                        <Repositories modeLoad={'all'} />
                    </> :
                        !props.repository ? <Repositories /> :
                            (props.profile && props.language && props.scheme && props.language
                                && props.profile
                                && props.organization
                                && props.repository
                                && props.database
                                && props.frontend
                                && props.backend) ?
                                <Menu /> :
                                <Loader />
                }
            </div>
        </div>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Side)
