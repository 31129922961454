
export default function markdown(text: any) {
    // If is object:
    if (typeof text === 'object') {
        return 'MARKDOWN NO OBJECT'
    }
    else {
        // If is string:
        if (typeof text === 'string') {
            // Convert ** to <strong>
            text = text.split("\\n").join("<br>");

            text = text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
            text = text.replace(/__(.*?)__/g, "<u>$1</u>");
            text = text.replace(/~~(.*?)~~/g, "<i>$1</i>");
            text = text.replace(/--(.*?)--/g, "<del>$1</del>");
            text = text.replace(/<<(.*?)>>/g, "<a href='$1'>Link</a>");
            text = text.replace(/<<(.*?)>>/g, "<a href='$1'>Link</a>");

            return text;
        }
        else {
            return 'sin traduccion'
        }
    }
}