
export default function translate(data: any, language: any = 'es') {
    // If is object:

    if (typeof language === 'object') {
        language = language.code
    }
    if (typeof data === 'object') {
        // If exist 'es'
        if (data[language]) {
            return data[language];
        }
    }
    else {
        // If is string:
        if (typeof data === 'string') {
            return data;
        }
        else {
            return 'sin traduccion'
        }
    }

}