import { useState } from "react";
import Form, { FormTabs, LayoutFormInputTypes } from "../form";
import LayoutInputToggle from "../toggle/LayoutInputToggle";
import './index.scss'
interface DispatchProps {
    icon: string,
    title: string,
    bubble: string,
    defaultValue: any,
    inputs: LayoutFormInputTypes[],
    onSubmit: (data: any) => void,
    tabs?: FormTabs[],
    onRemove: () => void
}

type Props = DispatchProps

function ListItem(props: Props) {
    const [tabSelected, setTabSelected] = useState<string | undefined>()
    return (
        <div className="Layout-list-item" >

            <LayoutInputToggle
                icon={props.icon}
                title={props.title}
                bubble={props.bubble}
                tabs={props.tabs}
                tabSelected={tabSelected}
                onTabSelected={
                    (tabIdSelected: string) => {
                        setTabSelected(tabIdSelected)
                    }
                }
                onRemove={props.onRemove} >
                <Form
                    tab={tabSelected}

                    defaultValue={props.defaultValue}
                    inputs={props.inputs}
                    onSubmit={props.onSubmit} />
            </LayoutInputToggle>

        </div>

    );
}

export default ListItem;
