import { connect } from "react-redux";
import markdown from "../../../functions/markdown";
import translate from "../../../functions/translation";
import { ProfileType } from "../../Profile/types";
import { Scheme } from "../types";
import { Section } from "../types";

// Connect redux
const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    scheme: Scheme
    profile: ProfileType
    section: Section,
    language: any,
    setSection?: (section: any) => void
}
type Props = DispatchProps

function Menu(props: Props) {

    const year = new Date().getFullYear();
    if (props?.scheme) {
        return (
            <div className="Footer">

                <ul>
                    <li>
                        <p>
                            <em dangerouslySetInnerHTML={{ __html: markdown(props.profile.logotype) }} />
                            <span dangerouslySetInnerHTML={{ __html: markdown(translate(props.profile.description)) }} />

                        </p>

                    </li>
                    <li>
                        <ul>
                            {
                                props?.scheme && props?.scheme.map((section: Section, index) => {
                                    if (section?.configuration?.navigation?.footer && props.language && props.language.code) {
                                        return (
                                            <li key={index}>
                                                <a
                                                    href={'/' + props.language.code + '/' + translate(section.configuration.url, props.language)}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        props.setSection?.(section);
                                                    }}>
                                                    {translate(section.configuration.name)}
                                                </a>
                                            </li>
                                        )
                                    }
                                    return null
                                })
                            }
                        </ul>
                    </li>
                </ul>
                <p>
                    <span dangerouslySetInnerHTML={{ __html: markdown(translate(props.profile.author)) }} />
                    {' '}{year} · {translate(props.profile.copyright)}
                </p>
            </div>
        );
    }
    return null
}

export default connect(mapStateToProps)(Menu)

