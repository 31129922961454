import { connect } from "react-redux";
import translate from "../../../functions/translation";
import { Translate } from "../types";

export interface SpecificationsComponent {
    type: 'Specifications',
    title?: Translate,
    description?: Translate,
    subtitle?: Translate,
    specifications?: {
        icon: 'string',
        title: Translate,
        description: Translate,
    }[]
}

const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    settings: SpecificationsComponent
}
type Props = DispatchProps

function Article(props: Props) {
    if (props?.settings) {
        return (
            <div className="Specifications">
                {props.settings.title && <h4>{translate(props.settings.title)}</h4>}
                {props.settings.subtitle && <h5>{translate(props.settings.subtitle)}</h5>}
                {props.settings.description && <p>{translate(props.settings.description)}</p>}
                {props.settings.specifications && <ul>{
                    props.settings.specifications.map((link: any, index: number) => {
                        return (
                            <li key={index}>
                                <i className={'la la-' + link.icon}></i>
                                <b>
                                    {translate(link.title)}
                                </b>
                                <p>
                                    {translate(link.description)}
                                </p>
                            </li>
                        )
                    })}
                </ul>}
            </div>
        );
    }
    return <div>asd</div>
}

export default connect(mapStateToProps)(Article)

