
import { connect } from "react-redux";
import {
    clearProject
} from '../../redux/actions'

// Connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    clearProject
}
interface DispatchProps {
    barDisplay: any,
    widthWindow: any,
    repository: any,
    clearProject: () => void,
    setOrganization: () => void,
    setBarDisplay: (s: any) => void
    setWidthWindow: (s: any) => void
}
type Props = DispatchProps
function Scenes(props: Props) {
    console.log('reposoitory:', props.repository)
    return (

        <div className="Window-controls">

            <div className='Selector-back' onClick={() => {
                props.clearProject()

            }}>
                <i className="la la-arrow-left"></i>

            </div>
            <div className='Selector-minus' onClick={() => {
                // Open in new tab
                window.open(props.repository.html_url, '_blank');

            }}>
                <i className="lab la-github"></i>

            </div>
            <div className='Selector-minus' onClick={() => {
                props.setBarDisplay(!props.barDisplay)
            }}>
                {
                    props.barDisplay ? <i className="la la-expand"></i> : <i className="la la-minus"></i>
                }
            </div>
            <div className='Selector-minus' onClick={() => {
                let newWidth = props.widthWindow === 'normal' ? 'small' : 'normal'
                props.setWidthWindow(newWidth)
            }}>
                <i className="la la-mobile"></i>
            </div>
            <div className='Selector-minus' onClick={() => {
                let newWidth = props.widthWindow === 'normal' ? 'small' : 'normal'
                props.setWidthWindow(newWidth)
            }}>

                <i className="la la-tablet"></i>

            </div>
            <div className='Selector-minus' onClick={() => {
                let newWidth = props.widthWindow === 'normal' ? 'small' : 'normal'
                props.setWidthWindow(newWidth)
            }}>
                <i className="la la-laptop"></i>
            </div>
            <div className='Selector-minus' onClick={() => {
                let newWidth = props.widthWindow === 'normal' ? 'small' : 'normal'
                props.setWidthWindow(newWidth)
            }}>

                <i className="la la-desktop"></i>

            </div>

        </div>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Scenes)
