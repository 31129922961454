function Ruler() {

    return (
        <div className="Ruler" >

        </div>
    );

}

export default Ruler

