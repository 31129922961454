
import { connect } from "react-redux";
import Controls from "../componets/Controls/component";
import Profile from "../componets/Layout/profile";
import Frontend from "../componets/Layout/frontend";
import Backend from "../componets/Layout/backend";
import Selector from "../componets/Selector";
import Applications from "../componets/Layout/applications";

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
}
interface DispatchProps {
    user: any,
    organization: any,
    repository: any,
    scene: string,
    database: any,
    frontend: any,
    backend: any,
}

function Menu(props: DispatchProps) {

    if (props.user && props.organization && props.repository && props.database && props.frontend && props.backend) {

        return (
            <>
                <Selector

                />
                {
                    props.scene === 'applications' && <Applications />
                }
                {
                    props.scene === 'deploy' && <Controls />
                }

                {
                    props.scene === 'profile' && <Profile />
                }

                {
                    props.scene === 'backend' && <Backend />
                }
                {
                    props.scene === 'frontend' && <Frontend />
                }
            </>
        );

    }
    else {
        return null
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
