import translate from "../../../functions/translation";
import { Translate } from "../types";

export interface ReviewsType {
    type: 'Reviews',
    title: Translate,
    subtitle: Translate,
    description: Translate,
    reviews: ReviewType[]

}

export interface ReviewType {
    name: Translate,
    company: Translate,
    message: Translate,
    rating: number,

}

interface DispatchProps {
    settings: ReviewsType
}

export default function Reviews(props: DispatchProps) {
    if (props?.settings) {
        return (
            <div className="Reviews">
                <h4>{translate(props.settings.subtitle)}</h4>
                <h3>{translate(props.settings.title)}</h3>
                <p>{translate(props.settings.description)}</p>
                <ul>
                    {
                        props?.settings?.reviews && props?.settings?.reviews.map((review: ReviewType, index: number) => {
                            return (
                                <li key={index}>
                                    <div></div>
                                    <p>{translate(review.message)}</p>
                                    {
                                        review.rating && <span className="rating">
                                            {
                                                [...Array(review.rating)].map((star, index) => {
                                                    return <i key={index} className={'las la-star'}></i>
                                                })
                                            }
                                        </span>
                                    }
                                    <b>{translate(review.name)}</b>
                                    <i>{translate(review.company)}</i>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        );

    }
    return null
}