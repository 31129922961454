import { connect } from "react-redux";
import { setLanguages, setScheme, setUser } from '../../redux/actions'
import './LayoutSectionLine.scss'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser, setScheme
}

interface DispatchProps {
    section: any,
    scheme: any,
    sectionIndex: number,
    onSelectedSection: () => void
    setScheme: (newSchema: any) => void

}
type Props = DispatchProps

function LayoutSectionLine(props: Props) {
    return (
        <div className="Layout-line" onClick={props.onSelectedSection}>

            <div className="Layout-line-title" >
                <i className={`Layout-line-title-icon la la-${props.section.icon}`} />

                <div className="Layout-line-title-text">
                    {props.section.id ? props.section.id : props.sectionIndex}

                </div>
                <div className="Layout-line-title-buttons">
                    <b>
                        {
                            props.section?.components?.length ? props.section?.components?.length : 0
                        } CMP
                    </b>
                    <i className="Layout-line-title-buttons-button la la-trash" onClick={(event) => {
                        // Delete section
                        console.log(props.sectionIndex, 'Super:', props.scheme)
                        let newSchema = (JSON.parse(JSON.stringify(props.scheme)))
                        newSchema.splice(props.sectionIndex, 1)
                        console.log(newSchema)
                        event.stopPropagation()
                        props.setScheme(newSchema)
                    }} />
                    <i className="Layout-line-title-buttons-button la la-arrow-down" onClick={(event) => {
                        event.stopPropagation()

                        let newSchema = (JSON.parse(JSON.stringify(props.scheme)))
                        // Move section down
                        newSchema.splice(props.sectionIndex, 1)
                        newSchema.splice(props.sectionIndex + 1, 0, props.section)
                        props.setScheme(newSchema)
                    }} />
                    <i className="Layout-line-title-buttons-button la la-arrow-up" onClick={(event) => {
                        event.stopPropagation()

                        let newSchema = (JSON.parse(JSON.stringify(props.scheme)))
                        // Move section up
                        newSchema.splice(props.sectionIndex, 1)
                        newSchema.splice(props.sectionIndex - 1, 0, props.section)
                        props.setScheme(newSchema)

                    }} />

                </div>
            </div >
        </div >

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSectionLine)

