
import { connect } from "react-redux";
import Styles from "../componets/Scheme/styles/component";
import Head from "../componets/Scheme/Head/component";

// Schemes:
import Menu from "../componets/Scheme/Menu/component";
import Footer from "../componets/Scheme/Footer/component";
import Header from "../componets/Scheme/Header/component";
import Article from "../componets/Scheme/Article/component";
import Reviews from "../componets/Scheme/Reviews/component";
import Downloads from "../componets/Scheme/Downloads/component";
import Splash from "../componets/Scheme/Splash/component";
import Plans from "../componets/Scheme/Plans/component";
import Qualities from "../componets/Scheme/Qualities/component";
import Glossary from "../componets/Scheme/Glossary/component";
import Gallery from "../componets/Scheme/Gallery/component";
import Specifications from "../componets/Scheme/Specifications/component";
import Contact from "../componets/Scheme/Contact/component";
import Definitions from "../componets/Scheme/Definitions/component";
import { useState } from "react";
import Componenter from "../componets/Componenter";
import Ruler from "../componets/Ruler";
import { IFrame } from "../componets/iframe/component";

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
}
interface DispatchProps {
    user: any,
    organization: any,
    repository: any,
    scheme: any,
    language: any,
    theme: any,
    profile: any,
    database: any,
    frontend: any,
    backend: any,
}
type Props = DispatchProps
function Page(props: Props) {
    const [section, setSection] = useState<any>(null);
    const [ruler, setRuler] = useState<boolean>(false);

    // Get section by id
    const getSection = (sectionId: string) => {
        const sectioner = props.scheme.find((sectionData: any) => sectionData.id === sectionId)
        return (sectioner)
    }
    let currentSection = undefined

    if (section && section.id) {
        currentSection = getSection(section.id)

    }
    const showPage = props.scheme
        && props.language
        && props.profile
        && props.user
        && props.organization
        && props.repository
        && props.database
        && props.frontend
        && props.backend
        ? true : false;
    if (!showPage) return null
    return (
        <IFrame>
            <div className="Page">
                <Styles theme={props.theme} />
                {
                    ruler && <Ruler />
                }
                <Componenter type={'Head'} >
                    <Head setSection={setSection} language={props.language} />
                </Componenter>
                <Componenter type={'Menu'} >
                    <Menu setSection={setSection} section={section} language={props.language} />
                </Componenter>
                {

                    currentSection && currentSection.components && currentSection.components.map((item: any, index: any) => {
                        return <Componenter type={item.type} key={index}>
                            {
                                item.type === 'Header' && <Header settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Article' && <Article settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Contact' && <Contact settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Downloads' && <Downloads settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Glossary' && <Glossary settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Gallery' && <Gallery settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Plans' && <Plans settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Qualities' && <Qualities settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Specifications' && <Specifications settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Definitions' && <Definitions settings={item} language={props.language} />
                            }
                            {
                                item.type === 'Splash' && <Splash settings={item} language={props.language} />
                            }

                            {item.type === 'Reviews' &&
                                <Reviews settings={item} />
                            }
                        </Componenter>
                    })
                }
                <Componenter type={'Footer'} >

                    <Footer setSection={setSection} />
                </Componenter>
            </div>

        </IFrame>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)
