import { connect } from "react-redux";
import markdown from "../../../functions/markdown";
import translate from "../../../functions/translation";
import { Translate } from "../types";

export interface ContactComponent {
    type: 'Contact',
    title?: Translate,
    description?: Translate,
    subtitle?: Translate,
    slogan?: Translate,

}

const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    settings: ContactComponent
}
type Props = DispatchProps

function Article(props: Props) {
    if (props?.settings) {
        return (
            <div className="Contact">
                <form>
                    <fieldset>
                        {props.settings.title && <h1>{translate(props.settings.title)}</h1>}
                        {props.settings.subtitle && <h2>{translate(props.settings.subtitle)}</h2>}
                        {props.settings.description && <p>{translate(props.settings.description)}</p>}
                        <label>Nombre</label>
                        <input type="text" />
                        <label>Email</label>
                        <input type="text" />
                        <label>Mensaje</label>
                        <textarea></textarea>
                        <button>Enviar</button>
                    </fieldset>
                    <div className="slogan" dangerouslySetInnerHTML={{ __html: markdown(translate(props.settings.slogan)) }} />
                </form>
            </div>
        );
    }
    return <div>asd</div>
}

export default connect(mapStateToProps)(Article)

