import { connect } from "react-redux";
import { clear, setRepository, setScheme, setUser } from '../../redux/actions'
import { useEffect } from "react";
import './styles.css'

// Connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setUser, clear, setScheme, setRepository
}
// Function login props redux connect

interface DispatchProps {
    user: any,
    setUser: (s: string) => void
    setScheme: (s?: any) => void
    setRepository: (s: any) => void
    clear: () => void
}
type Props = DispatchProps

function Login(props: Props) {
    // Setdata login redux
    const changeUrl = () => {
        let loginUrl = 'https://github.com/login/oauth/authorize'
        loginUrl += '?client_id=dc10139360d102575359&'
        loginUrl += 'redirect_uri = https://landface-dot-automatic-297623.ew.r.appspot.com&scope=repo,user,read:org'
        window.location.href = loginUrl
    }
    // UseEffect first time
    useEffect(() => {
        const raton = () => {
            props.setScheme()
            const code = new URLSearchParams(window.location.search).get('code') || '';
            if (code) {
                fetch(`https://brickter-github-dot-automatic-297623.ew.r.appspot.com/login/${code}`)
                    .then(response => response.json())
                    .then(async response => {
                        if (response.access_token) {
                            props.setUser(response.access_token)

                        }
                        else {
                            changeUrl()
                        }
                    })
            }
            else {
                changeUrl()
            }
        }
        raton()
    }, [])

    return (
        <div className="Login">

        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

