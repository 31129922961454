import { connect } from "react-redux";
import { setLanguages, setProfile, } from '../../../redux/actions'
import Form from "../components/form";

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setProfile
}

interface DispatchProps {
    profile: any,
    languages: any,
    setProfile: (data: any) => void
    setLanguages: (data: any) => void
}
type Props = DispatchProps

function LayoutLandingSplash(props: Props) {
    console.log('Acualizamos')
    return (
        <>
            <Form
                defaultValue={props.profile}
                inputs={
                    [

                        {
                            name: "logotype",
                            type: "text",
                            title: "Logotype",
                            icon: "las la-heading",
                        },
                        {
                            name: "apellation",
                            type: "text",
                            title: "Apellation",
                            icon: "las la-leaf",
                        },
                        {
                            name: "author",
                            type: "text",
                            title: "Author",
                            icon: "las la-building",

                        },
                        {
                            name: "domain",
                            type: "text",
                            title: "Domain",
                            icon: "las la-globe",

                        },
                        {
                            name: "name",
                            type: "text",
                            title: "Name",
                            icon: "las la-text-height",
                        },
                        {
                            name: "service",
                            type: "text",
                            title: "Service",
                            icon: "lab la-google-plus",

                        },
                        {
                            name: "description",
                            type: "translation",
                            title: "Descripción",
                            icon: "las la-quote-right",
                        },
                        {
                            name: "copyright",
                            type: "translation",
                            title: "Copyright",
                            icon: "las la-file-contract",
                        },
                        {
                            name: 'roles',
                            type: 'list',
                            previewParams: {
                                iconDefault: 'las la-heading',
                                titleDefault: 'Name',
                                title: 'name',
                                icon: 'icon',
                            },
                            inputs: [
                                {
                                    name: 'name',
                                    type: 'text',
                                    title: 'Name',
                                    icon: 'las la-heading',
                                },
                                {
                                    name: 'icon',
                                    type: 'icon',
                                    title: 'Icon',
                                    icon: 'las la-shapes',
                                }
                            ],
                            newDefaultvalue: {},
                            title: 'Roles',
                            icon: 'las la-user-tie',
                        },
                        {
                            name: 'applications',
                            type: 'list',

                            previewParams: {
                                iconDefault: 'las la-atom',
                                titleDefault: 'Application',
                                title: 'name',
                            },
                            inputs: [
                                {
                                    name: 'name',
                                    type: 'text',
                                    title: 'Name',
                                    icon: 'las la-heading',
                                },
                                {
                                    name: 'code',
                                    type: 'text',
                                    title: 'Code',
                                    icon: 'las la-flag',
                                },
                                {
                                    name: "type",
                                    type: "select",
                                    title: "Type",
                                    icon: "las la-key",
                                    options: [
                                        {
                                            value: 'landing',
                                            label: 'Landing'
                                        },
                                        {
                                            value: 'frontend',
                                            label: 'Frontend'
                                        },
                                        {
                                            value: 'backend',
                                            label: 'Backend'
                                        }
                                    ]
                                },
                                {
                                    name: "language",
                                    type: "select",
                                    title: "Language",
                                    icon: "las la-code",
                                    options: [
                                        {
                                            value: 'node',
                                            label: 'Node'
                                        },
                                        {
                                            value: 'python',
                                            label: 'Python'
                                        },
                                        {
                                            value: 'rust',
                                            label: 'Rust'
                                        },
                                        {
                                            value: 'go',
                                            label: 'Go'
                                        }
                                    ]
                                },
                            ],
                            newDefaultvalue: {

                            },
                            title: 'Aplicaciones',
                            icon: 'las la-atom',
                        }
                    ]
                }
                onSubmit={(data) => {
                    console.log('daton:', data)
                    props.setProfile(JSON.parse(JSON.stringify(data)))
                }} />
            <Form
                defaultValue={{ languages: props.languages }}
                inputs={
                    [

                        {
                            name: 'languages',
                            type: 'list',

                            previewParams: {
                                iconDefault: 'las la-flag',
                                titleDefault: 'Language',
                                title: 'name',
                            },
                            inputs: [
                                {
                                    name: 'name',
                                    type: 'text',
                                    title: 'Name',
                                    icon: 'las la-heading',
                                },
                                {
                                    name: 'code',
                                    type: 'text',
                                    title: 'Code',
                                    icon: 'las la-flag',
                                },
                            ],
                            newDefaultvalue: {

                            },
                            title: 'Languages',
                            icon: 'las la-language',
                        }
                    ]
                }
                onSubmit={(data) => {
                    console.log('Reci:', data)
                    props.setLanguages(JSON.parse(JSON.stringify(data.languages)))
                }} />

        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutLandingSplash)
