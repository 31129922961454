export const SET_USER = 'SET_USER';
export const CLEAR = 'CLEAR';
export const SET_SCHEME = 'SET_SCHEME';
export const SET_SCHEME_GITHUB = 'SET_SCHEME_GITHUB';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_REPOSITORY = 'SET_REPOSITORY';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_GITHUB = 'SET_PROFILE_GITHUB';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_LANGUAGES_GITHUB = 'SET_LANGUAGES_GITHUB';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_MODE = 'SET_MODE';
export const SET_THEME = 'SET_THEME';
export const CLEAR_PROJECT = 'CLEAR_PROJECT';
export const SET_DATABASE = 'SET_DATABASE';
export const SET_DATABASE_GITHUB = 'SET_MODE_GITHUB';
export const SET_FRONTEND = 'SET_FRONTEND';
export const SET_FRONTEND_GITHUB = 'SET_FRONTEND_GITHUB';
export const SET_BACKEND = 'SET_BACKEND';
export const SET_BACKEND_GITHUB = 'SET_BACKEND_GITHUB';
export const SET_SCENE = 'SET_SCENE';
export const SET_APPLICATION = 'SET_APPLICATION';