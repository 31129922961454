import { useState } from "react";
import { connect } from "react-redux";
import { setLanguages, setUser } from '../../../../redux/actions'
import { FormTabs } from "../form";
import LayoutInputTitle from "../title";
import './index.scss'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser
}

interface DispatchProps {
    title: string, children: any, icon: string,
    onRemove?: (data: any) => void,
    tabs?: FormTabs[],
    bubble?: string,
    tabSelected?: string,
    onTabSelected?: (tabId: string) => void,
}
type Props = DispatchProps

function LayoutInputToggle(props: Props) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={`Layout-toggle ${isOpen ? 'Layout-toggle-open' : ''}`}>
            <div className="Layout-toggle-title">
                <LayoutInputTitle
                    bubble={props.bubble}
                    tabs={props.tabs}
                    tabSelected={props.tabSelected}
                    onTabSelected={props.onTabSelected}
                    onRemove={props.onRemove} icon={props.icon} title={props.title} toggle={isOpen}
                    onChangeToggle={
                        () => {
                            setIsOpen(!isOpen)
                        }
                    }
                    onOpenToggle={
                        () => {
                            setIsOpen(true)
                        }
                    }
                    onCloseToggle={
                        () => {
                            setIsOpen(false)
                        }
                    }

                />
            </div>

            {
                isOpen && <div className="Layout-toggle-content">
                    <div className="Layout-toggle-content-frame">
                        {props.children && props.children}</div>
                </div>
            }
        </div>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutInputToggle)

