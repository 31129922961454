import { Octokit } from "@octokit/rest";

export default async function Get(userToken: string, owner: string, repo: string, path: string, branch: string = 'main') {

    // Return promise
    return new Promise(async (resolve, reject) => {
        // Login to Github
        const octokit = await new Octokit({
            auth: userToken,
        });

        console.log('Git Get:', userToken)
        console.log('Git owner:', owner)
        console.log('Git repo:', repo)
        console.log('Git path:', path)
        try {
            console.log('Pedimos archivo')
            // Get file if exist
            let fileInGithub: any = await octokit.repos.getContent({
                owner,
                repo,
                path,
            });
            console.log('Se recibe archivo', fileInGithub)
            resolve(fileInGithub)
        }
        catch (error) {
            console.log('Error, parece que no llega')

            reject()
        }

    })

}