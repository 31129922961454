import { useState } from "react";
import { connect } from "react-redux";
import { setLanguages, setUser } from '../../redux/actions'
import './LayoutSection.scss'
import LayoutSectionComponents from "./LayoutSectionComponents";
import LayoutSectionConfiguration from './LayoutSectionConfiguration'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser
}

interface DispatchProps {
    sectionIndex: number,
    section: any,
    onDeselectSection: () => void
}
type Props = DispatchProps

function LayoutSection(props: Props) {
    const [scene, setScene] = useState<string>('components')

    return (
        <div className="Layout-section Layout-slide-left">
            <div className="Layout-back" onClick={props.onDeselectSection}>
                <i className="la la-arrow-left" />
                Volver a todas las secciones
            </div>
            <div className="Layout-section-title" >
                {props.section.id}
                <b>
                    {
                        props.section?.components?.length
                    } componentes
                </b>

            </div>
            <div className="Layout-section-switch">
                <b className={scene === 'configuration' ? 'Layout-section-switch-active' : ''} onClick={() => {
                    setScene('configuration')
                }}>
                    Configuración
                </b>
                <b className={scene === 'components' ? 'Layout-section-switch-active' : ''} onClick={() => {
                    setScene('components')
                }}>
                    Components
                </b>

            </div>
            {scene === 'configuration' && <LayoutSectionConfiguration sectionIndex={props.sectionIndex} section={props.section} />}
            {scene === 'components' && <LayoutSectionComponents sectionIndex={props.sectionIndex} />}

        </div >

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSection)

