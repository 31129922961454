import { connect } from "react-redux";
import { isEmpty } from "../../../functions/empty";
import markdown from "../../../functions/markdown";
import translate from "../../../functions/translation";
import { Section, Translate } from "../types";

export interface HeaderComponent {
    type: 'Slide',
    title?: Translate,
    description?: Translate,
    subtitle?: Translate,
    background: 'image' | 'video',
    links?: {
        text: Translate,
        url: string
    }[]
}

// Connect redux
const mapStateToProps = (state: any, rest: any) => (state)

interface DispatchProps {
    user: any,
    organization: any,
    repository: any,
    section: Section,
    index: number,
    settings: HeaderComponent
}
type Props = DispatchProps

function Header(props: Props) {

    let containerStyle;
    if (props.settings?.background === 'image') {
        // ContainerStyle = {
        //     BackgroundImage: `url(/graphics/images/${props.section.id}/${props.index}/background.jpg)`,
        // }
    }
    if (props?.settings) {
        return (
            <div className="Header"
            // OnClick={onDrag}
            // Style={styles}
            >
                <div className="Frame" style={containerStyle}>

                    {
                        props.settings?.background && props.settings?.background === 'video' && <video autoPlay loop muted >
                            <source src="/graphics/videos/example.mp4" type="video/mp4" />
                        </video>
                    }

                    {props.settings.title && <h1 dangerouslySetInnerHTML={{ __html: markdown(translate(props.settings.title)) }} />}
                    {props.settings.subtitle && <h2 dangerouslySetInnerHTML={{ __html: markdown(translate(props.settings.subtitle)) }} />}
                    {props.settings.description && !isEmpty(props.settings.description) && <p
                        dangerouslySetInnerHTML={{ __html: markdown(translate(props.settings.description)) }} />}
                    {props.settings.links && <ul>{
                        props.settings.links.map((link: any, index: number) => {
                            return (
                                <li key={index}>
                                    <a href={link.url}>
                                        {translate(link.text)}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>}
                </div>
            </ div >
        );
    }
    return <div>asd</div>
}

export default connect(mapStateToProps)(Header)

