import { useState } from "react";
import { connect } from "react-redux";
import { setLanguages, setUser } from '../../../../redux/actions'
import LayoutInputToggle from "../toggle/LayoutInputToggle";
import './index.scss'
import Iconers from './icons.json'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguages, setUser
}

interface DispatchProps {
    title: string,
    icon: string,
    onSave: (data: any) => void
    defaultValue: any,

}
type Props = DispatchProps

function LayoutInputSelect(props: Props) {

    const [search, setSearch] = useState('')
    const [value, setValue] = useState(props.defaultValue)
    let icons: any = Iconers.list
    if (search) {
        icons = icons.filter((item: any) => {
            return item.toLowerCase().indexOf(search.toLowerCase()) > -1
        })
    }
    return (
        <div className="Layout-icon" >
            <LayoutInputToggle title={props.title} icon={props.icon}>
                <div className="Layour-icon-search">
                    <input placeholder="Search icon..." type="text" value={search} onChange={(event) => {
                        setSearch(event.target.value)
                    }} />
                </div>
                <div className="Layour-icon-list">
                    {
                        icons.map((item: any, index: number) => {
                            return (
                                <div key={index} className="Layour-icon-list-icon" onClick={(event) => {
                                    setValue(item)

                                    props.onSave(item)
                                }} >
                                    <i className={`${item}`}></i>
                                </div>)
                        })
                    }
                </div>
            </LayoutInputToggle >

        </div >

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutInputSelect)

